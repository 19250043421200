import { Fragment, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Button, Col, Modal, Alert, Container } from "react-bootstrap";
import { ListarContratoRequest } from "../../../store/modules/contrato/actions";
import { InserirSolicitacaoFornecimentoVersaoRequest } from "../../../store/modules/solicitacaoFornecimentoVersao/actions";
import { ListarRegiaoRequest } from "../../../store/modules/regiao/actions";
import { ListarTipoSolicitacaoRequest, BuscarTipoSolicitacaoRequest } from '../../../store/modules/tipoSolicitacao/actions';
import { useHistory, useParams } from "react-router-dom";
import { ListarSolicitacaoFornecimentoVersaoStatusRequest } from "../../../store/modules/solicitacaoFornecimentoVersaoStatus/actions";
import {
    InserirSolicitacaoFornecimentoRequest, AlterarSolicitacaoFornecimentoRequest,
    BuscarSolicitacaoFornecimentoPorIdRequest, ListarSolicitacaoFornecimentoCamposVerificacaoRapidaRequest,
    LimparSolicitacaoFornecimento, GerarOficioRequest
} from '../../../store/modules/solicitacaoFornecimento/actions';
import { ListarPrioridadeRequest } from '../../../store/modules/prioridade/actions';
import { toast } from 'react-toastify';
import { ListarStatusSolicitacaoFornecimentoRequest, ListarStatusSolicitacaoFornecimentoCompletoRequest } from '../../../store/modules/statusSolicitacaoFornecimento/actions';
import { ListarFornecedorRequest } from "../../../store/modules/fornecedor/actions";
import { ListarBairroRequest } from "../../../store/modules/bairro/actions";
import { ListarCompletoContratoFornecedorRequest, ListarContratoFornecedorRequest } from "../../../store/modules/contratoFornecedor/actions";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ListarInteressadoRequest } from '../../../store/modules/interessado/actions';
import PdfSolicitacaoFornecimento from "../../../components/PdfSolicitacaoFornecimento/pdfSolicitacaoFornecimento";
import { ListarDadosExportarPDFSolicitacaoFornecimentoEventoRequest, ListarDadosExportarPDFSolicitacaoFornecimentoRequest } from "../../../store/modules/relatorio/actions";
import InputMask from "react-input-mask";
import Loading from '../../../components/Loading/loading';
import { ListarUsuarioRequest } from '../../../store/modules/usuario/actions';
import { ListarOrgaoRequest } from '../../../store/modules/orgao/actions';
import moment from 'moment';
import 'moment/locale/pt-br';
import PdfOrcamentoEvento from "../../../components/pdfOrcamentoEvento/pdfOrcamentoEvento";
import ModalInteressado from "../../../components/ModalInteressado/modalInteressado";
import ModalDestinatario from "../../../components/ModalDestinatario/modalDestinatario";
import Select from 'react-select';
import ModalOrgao from "../../../components/ModalOrgao/modalOrgao";

export default function SolicitacaoFornecimento() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const usuario = JSON.parse(localStorage.getItem('token')).usuario;

    const listaUsuario = useSelector(state => state.usuario.listaUsuario);
    const listaPrioridade = useSelector(state => state.prioridade.listaPrioridade);
    const listaContrato = useSelector(state => state.contrato.listaContrato);
    const listaTipoSolicitacao = useSelector(state => state.tipoSolicitacao.listaTipoSolicitacao);
    const listaRegiao = useSelector(state => state.regiao.listaRegiao);
    const solicitacaoFornecimento = useSelector(state => state.solicitacaoFornecimento.solicitacaoFornecimentoCompleto);
    const carregando = useSelector(state => state.solicitacaoFornecimento.carregando);
    const listaSolicitacaoFornecimentoVersaoStatus = useSelector(state =>
        state.solicitacaoFornecimentoVersaoStatus.listaSolicitacaoFornecimentoVersaoStatus);
    const listaStatusSolicitacaoFornecimentoCompleto = useSelector(state => state.statusSolicitacaoFornecimento.listaStatusSolicitacaoFornecimentoCompleto);
    const listaContratoFornecedor = useSelector(state => state.contratoFornecedor.listaContratoFornecedor);
    const listaFornecedor = useSelector(state => state.fornecedor.listaFornecedor);
    const listaBairro = useSelector(state => state.bairro.listaBairro);
    const listaInteressado = useSelector(state => state.interessado.listaInteressado);
    const listaDadosExportarPDFSolicitacaoFornecimento = useSelector(state => state.relatorio.listaDadosExportarPDFSolicitacaoFornecimento);
    const listaDadosExportarPDFSolicitacaoFornecimentoEvento = useSelector(state => state.relatorio.listaDadosExportarPDFSolicitacaoFornecimentoEvento);
    const tipoSolicitacaoCompleto = useSelector(state => state.tipoSolicitacao.tipoSolicitacaoCompleto);
    const usuarioCompleto = useSelector(state => state.usuario.usuario);
    const listarSolicitacaoFornecimentoCamposVerificacaoRapida = useSelector(state => state.solicitacaoFornecimento.listarSolicitacaoFornecimentoCamposVerificacaoRapida);
    const listaOrgao = useSelector(state => state.orgao.listaOrgao);
    const listaEventoAdicional = useSelector(state => state.eventoAdicional.listaEventoAdicional);

    const [idContrato, setIdContrato] = useState('');
    const [idContratoVersao, setIdContratoVersao] = useState('');
    const [idTipoSolicitacao, setIdTipoSolicitacao] = useState('');
    const [numeroProcesso, setNumeroProcesso] = useState('');
    const [numeroOficio, setNumeroOficio] = useState('');
    const [descricao, setDescricao] = useState('');
    const [endereco, setEndereco] = useState('');
    const [localRepetido, setLocalRepetido] = useState('');
    const [numeroProcessoRepetido, setNumeroProcessoRepetido] = useState('');
    const [assuntoRepetido, setAssuntoRepetido] = useState('');
    const [idRegiao, setIdRegiao] = useState('');
    const [idSolicitacaoVersao, setIdSolicitacaoVersao] = useState('');
    const [idSolicitacaoFornecimento, setIdSolicitacaoFornecimento] = useState('');
    const [observacao, setObservacao] = useState('');
    const [observacaoVersao, setObservacaoVersao] = useState('');
    const [observacaoReprovacao, setObservacaoReprovacao] = useState('');
    const [numeroOrcamento, setNumeroOrcamento] = useState('');
    const [show, setShow] = useState(false);
    const [showInteressado, setShowInteressado] = useState(false);
    const [showOrgao, setShowOrgao] = useState(false);
    const [validated, setValidated] = useState(false);
    const [dados, setDados] = useState('');
    const [idFornecedor, setIdFornecedor] = useState('');
    const [idPrioridade, setIdPrioridade] = useState('');
    const [validatedObservacaoNovaVersao, setValidatedObservacaoNovaVersao] = useState(false);
    const [idBairro, setIdBairro] = useState('');
    const [prazoDias, setPrazoDias] = useState('');
    const [numeroOficioSolicitacaoOrcamento, setNumeroOficioSolicitacaoOrcamento] = useState('');
    const [idAutorizador, setIdAutorizador] = useState('');
    const [idDestinatario, setIdDestinatario] = useState('');
    const [descricaoLocal, setDescricaoLocal] = useState('');
    const [assunto, setAssunto] = useState('');
    const [ddd, setDdd] = useState('');
    const [telefone, setTelefone] = useState('');
    const [dddFixo, setDddFixo] = useState('');
    const [telefoneFixo, setTelefoneFixo] = useState('');
    const [listaResposta, setListaResposta] = useState([]);
    const [carregandoTime, setCarregandoTime] = useState(false);
    const [idInteressado, setIdInteressado] = useState('');
    const [interessado, setInteressado] = useState('');
    const [interessadoSelecinado, setInteressadoSelecinado] = useState('');
    const [orgaoSelecinado, setOrgaoSelecinado] = useState('');
    const [orgao, setOrgao] = useState('');
    const [idOrgao, setIdOrgao] = useState('');
    const [idSolicitacao, setIdSolicitacao] = useState('');
    const [showDestinatario, setShowDestinatario] = useState(false);
    const [destinatarioSelecionado, setDestinatarioSelecionado] = useState('');

    const handleCloseInteressado = () => setShowInteressado(false);
    const handleShowInteressado = () => setShowInteressado(true);

    const handleCloseOrgao = () => setShowOrgao(false);
    const handleShowOrgao = () => setShowOrgao(true);

    const handleCloseDestinatario = () => setShowDestinatario(false);
    const handleShowDestinatario = () => setShowDestinatario(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let [token] = useState(JSON.parse(localStorage.getItem('token')).token);
    const [inserir, setInserir] = useState(false);
    const [alterar, setAlterar] = useState(false);

    useEffect(() => {
        if (interessadoSelecinado != '' && interessadoSelecinado != null) {
            setInteressado(interessadoSelecinado.nome);
            setIdInteressado(interessadoSelecinado.id);
            setEndereco(interessadoSelecinado.endereco);
            setDdd(interessadoSelecinado.numeroDdd);
            setDddFixo(interessadoSelecinado.numeroDddFixo);
            setTelefoneFixo(interessadoSelecinado.numeroTelefoneFixo);
            setTelefone(interessadoSelecinado.numeroTelefone);
        }

    }, [interessadoSelecinado])

    useEffect(() => {

        MudarValor("Destinatário", `${destinatarioSelecionado.id}`, "select",
            destinatarioSelecionado.id);

    }, [destinatarioSelecionado])

    useEffect(() => {
        if (orgaoSelecinado != '' && orgaoSelecinado != null) {

            setIdOrgao(orgaoSelecinado.id);
            setOrgao(orgaoSelecinado.descricao);

        }

    }, [orgaoSelecinado])
    useEffect(() => {

        //Permissão de Visualizar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoMenu").length == 0) {
            history.push(`/`);
            toast.error('Você não tem permissão para entrar nessa tela.');
        }

        //Permissão inserir e editar
        if (token.role.filter(r => r == "SolicitacaoFornecimentoInserir").length == 1) {
            setInserir(true);
        }
        if (token.role.filter(r => r == "SolicitacaoFornecimentoAlterar").length == 1) {
            setAlterar(true);
        }

    }, [])

    useEffect(() => () =>
        dispatch(LimparSolicitacaoFornecimento())
        , []);

    useEffect(() => {

        moment.locale('pt-br');

        dispatch(ListarSolicitacaoFornecimentoCamposVerificacaoRapidaRequest());
        dispatch(ListarBairroRequest());
        dispatch(ListarInteressadoRequest());
        dispatch(ListarPrioridadeRequest());
        dispatch(ListarContratoRequest());
        dispatch(ListarRegiaoRequest());
        dispatch(ListarTipoSolicitacaoRequest());

        if (id != undefined)
            dispatch(ListarStatusSolicitacaoFornecimentoCompletoRequest(id));
        dispatch(ListarFornecedorRequest());
        dispatch(ListarContratoFornecedorRequest());
        dispatch(ListarOrgaoRequest());
        dispatch(ListarUsuarioRequest());

        if (id != undefined) {
            dispatch(BuscarSolicitacaoFornecimentoPorIdRequest(id));
            dispatch(ListarSolicitacaoFornecimentoVersaoStatusRequest(id));
        }

        if (id == undefined)
            setCarregandoTime(true);

        /*setTimeout(() => {
            setCarregandoTime(true);
        }, 2500)*/

    }, [])

    useEffect(() => {

        if (solicitacaoFornecimento != null && solicitacaoFornecimento.id == parseInt(id) && listaInteressado != undefined) {
            CarregarSolicitacaoFornecimento();
            dispatch(ListarCompletoContratoFornecedorRequest(solicitacaoFornecimento.idContrato));
        }

        if (solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.inputServico ||
            solicitacaoFornecimento != null && !solicitacaoFornecimento.tipoSolicitacao.servico) {

            /* var listaInputsEditar = solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.map(x => {
                return{
                    input: x.caracteristicaResposta.caracteristica.descricaoLabel,
                    valor: x.valorVarchar
                }
            })
            setListaEditarInput(listaInputsEditar); */

            let lista = solicitacaoFornecimento.listaSolicitacaoFornecimentoCaracteristicaRespostaApiModel.map(x => {
                return {
                    id: x.id,
                    descricao: x.caracteristicaResposta.caracteristica.descricao,
                    valor:
                        x.valorVarchar != '' ? x.valorVarchar :
                            x.valorDateTime != null ? x.valorDateTime.split('T')[0] :
                                x.valorInteger != null ? x.valorInteger :
                                    x.valorVarcharGrande != '' ? x.valorVarcharGrande : '',
                    tipo: x.caracteristicaResposta.caracteristica.tipoCaracteristica.descricao,
                    idCaracteristicaResposta: x.caracteristicaResposta.id,
                    nulavel: x.caracteristicaResposta.caracteristica.nulavel,
                    inputLargo: x.caracteristicaResposta.caracteristica.tipoCaracteristica.inputLargo
                }
            })

            setListaResposta(lista);
        }

    }, [solicitacaoFornecimento, listaInteressado, id])

    useEffect(() => {
        if (id != undefined) {
            dispatch(ListarDadosExportarPDFSolicitacaoFornecimentoRequest({ 'IdSolicitacaoFornecimento': id }));
            dispatch(ListarDadosExportarPDFSolicitacaoFornecimentoEventoRequest({ 'IdSolicitacaoFornecimento': id }))
        }
    }, [id, solicitacaoFornecimento])

    useEffect(() => {



        if (tipoSolicitacaoCompleto != null && id == undefined /*&& listaResposta.length == 0*/) {
            //console.log(tipoSolicitacaoCompleto.listaFormularioCaracteristica)
            let lista = tipoSolicitacaoCompleto.listaFormularioCaracteristica.map(x => {
                return {
                    id: 0,
                    descricao: x.caracteristica.descricao,
                    valor: '',
                    tipo: '',
                    idCaracteristicaResposta: x.caracteristica.tipoCaracteristica.descricao == 'select' ? 0 : x.caracteristica.caracteristicaResposta[0].id,
                    nulavel: x.caracteristica.nulavel,
                    inputLargo: x.caracteristica.tipoCaracteristica.inputLargo
                }
            })

            setListaResposta(lista);
        }



    }, [tipoSolicitacaoCompleto])

    useEffect(() => {

        dispatch(BuscarTipoSolicitacaoRequest(parseInt(idTipoSolicitacao), id != undefined ? id : null));

    }, [idTipoSolicitacao])

    const handleSubmit = (event) => {

        if (event.currentTarget.checkValidity() === false || idContrato == '' || idTipoSolicitacao == '' ||
            idContrato == false || idTipoSolicitacao == false || ((listaTipoSolicitacao != undefined && listaTipoSolicitacao.length > 0 &&
                (listaTipoSolicitacao.filter(m => m.id == idTipoSolicitacao)[0].servico == true ||
                    listaTipoSolicitacao.filter(m => m.id == idTipoSolicitacao)[0].inputServico == true)) && ((tipoSolicitacaoCompleto != null && tipoSolicitacaoCompleto.semOficio == false
                        && (idFornecedor == '' ||
                            idPrioridade == '' || idBairro == '' || idRegiao == '' || idAutorizador == '' || idDestinatario == '' || orgao == '' ||
                            idFornecedor == false || idPrioridade == false || idBairro == false || idRegiao == false || idAutorizador == false
                            || idDestinatario == false || orgao == false)) || (tipoSolicitacaoCompleto != null && tipoSolicitacaoCompleto.semOficio &&
                                (idFornecedor == '' ||
                                    idPrioridade == '' || idBairro == '' || idRegiao == '' || orgao == '' ||
                                    idFornecedor == false || idPrioridade == false || idBairro == false || idRegiao == false || orgao == false))))) {

            event.preventDefault();
        } else {

            InserirSolicitaçãoFornecimento(event);
        }

        setValidated(true);
    };

    const handleSubmitNovaVersao = (event) => {

        if (observacaoVersao == '') {
            event.preventDefault();
        } else {
            CriarNovaVersaoSolicitacao();
        }

        setValidatedObservacaoNovaVersao(true);
    };

    function CarregarSolicitacaoFornecimento() {

        // Solicitação
        setIdSolicitacao(solicitacaoFornecimento.id);
        setIdContrato(solicitacaoFornecimento.idContrato);
        setIdContratoVersao(solicitacaoFornecimento.idContratoVersao);
        setIdTipoSolicitacao(solicitacaoFornecimento.idTipoSolicitacao);
        setNumeroProcesso(solicitacaoFornecimento.numeroProcesso);
        setNumeroOficio(solicitacaoFornecimento.numeroOficio);
        setDescricao(solicitacaoFornecimento.descricaoSolicitacao);
        setObservacao(solicitacaoFornecimento.observacao);
        setEndereco(solicitacaoFornecimento.endereco == null ||
            solicitacaoFornecimento.endereco == undefined ||
            solicitacaoFornecimento.endereco == '' ? null :
            listaInteressado.filter(x => x.id == solicitacaoFornecimento.idInteressado)[0].endereco == null ? '' :
                listaInteressado.filter(x => x.id == solicitacaoFornecimento.idInteressado)[0].endereco);
        setIdRegiao(solicitacaoFornecimento.idRegiao);
        setIdPrioridade(solicitacaoFornecimento.idPrioridade);
        setIdBairro(solicitacaoFornecimento.idBairro);
        setPrazoDias(solicitacaoFornecimento.prazoDias);
        setNumeroOficioSolicitacaoOrcamento(solicitacaoFornecimento.numeroOficioSolicitacaoOrcamento);
        setIdAutorizador(solicitacaoFornecimento.idAutorizador);
        setIdDestinatario(solicitacaoFornecimento.idDestinatario);
        setDescricaoLocal(solicitacaoFornecimento.descricaoLocal);
        setAssunto(solicitacaoFornecimento.assunto);
        setDdd(solicitacaoFornecimento.ddd == null ? null :
            listaInteressado.filter(x => x.id == solicitacaoFornecimento.idInteressado)[0].numeroDdd == null ? '' :
                listaInteressado.filter(x => x.id == solicitacaoFornecimento.idInteressado)[0].numeroDdd);
        setTelefone(solicitacaoFornecimento.telefone == null ? null :
            listaInteressado.filter(x => x.id == solicitacaoFornecimento.idInteressado)[0].numeroTelefone == null ? '' :
                listaInteressado.filter(x => x.id == solicitacaoFornecimento.idInteressado)[0].numeroTelefone);
        setDddFixo(solicitacaoFornecimento.dddFixo == null ? null :
            listaInteressado.filter(x => x.id == solicitacaoFornecimento.idInteressado)[0].numeroDddFixo == null ? '' :
                listaInteressado.filter(x => x.id == solicitacaoFornecimento.idInteressado)[0].numeroDddFixo);
        setTelefoneFixo(solicitacaoFornecimento.telefoneFixo == null ? null :
            listaInteressado.filter(x => x.id == solicitacaoFornecimento.idInteressado)[0].numeroTelefoneFixo == null ? '' :
                listaInteressado.filter(x => x.id == solicitacaoFornecimento.idInteressado)[0].numeroTelefoneFixo);
        setIdInteressado(solicitacaoFornecimento.idInteressado);
        setInteressado(solicitacaoFornecimento.interessado.nome);
        setEndereco(solicitacaoFornecimento.endereco);
        setDdd(solicitacaoFornecimento.interessado.numeroDdd);
        setDddFixo(solicitacaoFornecimento.interessado.numeroDddFixo);
        setTelefoneFixo(solicitacaoFornecimento.interessado.numeroTelefoneFixo);
        setTelefone(solicitacaoFornecimento.interessado.numeroTelefone);
        setIdOrgao(solicitacaoFornecimento.idOrgao ? solicitacaoFornecimento.idOrgao : null);

        if (listaOrgao != undefined && listaOrgao.length > 0) {

            var selecionado = listaOrgao.filter(d => d.id == solicitacaoFornecimento.idOrgao);
            if (selecionado.length > 0) {
                setOrgao(selecionado[0].descricao);
            }
        }


        if (solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao.servico ||
            solicitacaoFornecimento != null && solicitacaoFornecimento.tipoSolicitacao != null &&
            solicitacaoFornecimento.tipoSolicitacao.semOficio &&
            listaEventoAdicional != undefined && listaEventoAdicional.filter(x => x.tipoSolicitacao.servico == true).length > 0) {
            if (solicitacaoFornecimento.idContratoFornecedor != null) {
                setIdFornecedor(solicitacaoFornecimento.idContratoFornecedor);
            }

            // Versão
            setIdSolicitacaoVersao(solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id);
            setIdSolicitacaoFornecimento(solicitacaoFornecimento.id);
            setObservacaoVersao(solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.observacaoVersao);
            setNumeroOrcamento(solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento);

            // Status
            if (solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel !== null) {
                setObservacaoReprovacao(solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel.observacaoReprovacao);
            }
        }
        else {
            setIdFornecedor(solicitacaoFornecimento.idFornecedor)
        }

        setCarregandoTime(true);
    }

    function ValidaDdd() {
        const dddRegex = /^\d{2}$/;
        if (dddRegex.test(String(ddd))) {
            return true;
        } else {
            return false;
        }
    }

    function ValidaTelefone() {
        const telefoneRegex = /^\d{1}\d{4} \d{4}$/;
        const telefoneRegex2 = /^\d{1}\d{4}\d{4}$/;
        if (telefoneRegex.test(String(telefone)) || telefoneRegex2.test(String(telefone))) {
            return true;
        } else {
            return false;
        }
    }

    function InserirSolicitaçãoFornecimento(e) {

        e.preventDefault();

        /*
        //validação do campo numeroProcesso
        if (numeroProcesso == ' ' || numeroProcesso == ''
            || numeroProcesso == null || numeroProcesso == undefined) {
            toast.error(`Número Processo Inválido`);
            return;
        }
        if (numeroProcesso.length > 50) {
            toast.error(`O Número do Processo não pode conter mais que 50 caracteres`);
            return;
        }

        //validação do campo descricao
        if (descricao == ' ' || descricao == ''
            || descricao == null || descricao == undefined) {
            toast.error(`Descrição Inválida`);
            return;
        }
        if (descricao.length > 400) {
            toast.error(`A descrição não pode conter mais que 100 caracteres`);
            return;
        }

        //validação do campo observação
        if (observacao.length > 100) {
            toast.error(`A observação não pode conter mais que 100 caracteres`);
            return;
        }

        //validação do campo endereco
        if (endereco == ' ' || endereco == ''
            || endereco == null || endereco == undefined) {
            toast.error(`Endereço Inválido`);
            return;
        }
        if (endereco.length > 400) {
            toast.error(`O endereço não pode conter mais que 200 caracteres`);
            return;
        }
        */

        var listaSolicitacaoFornecimentoCaracteristicaResposta = [];

        listaSolicitacaoFornecimentoCaracteristicaResposta = listaResposta.map(x => {
            return {
                'Id': x.id,
                'IdSolicitacaoFornecimento': solicitacaoFornecimento == null ? 0 : solicitacaoFornecimento.id,
                'IdCaracteristicaResposta': x.idCaracteristicaResposta,
                'ValorVarchar': (x.tipo == 'text' || x.tipo == 'select') && x.inputLargo == false ? x.valor : '',
                'ValorVarcharGrande': x.tipo == 'text' && x.inputLargo ? x.valor : '',
                'ValorInteger': x.tipo == 'number' ? x.valor == '' ? null : x.valor : null,
                'ValorBoolean': x.tipo == '' ? null : null,
                'ValorDouble': x.tipo == '' ? null : null,
                'ValorDateTime': x.tipo == 'date' ?
                    x.valor != '' ? x.valor : null : null
            }
        })

        let solicitacaoCompleta = {
            'Id': solicitacaoFornecimento == null ? 0 : solicitacaoFornecimento.id,
            'IdContratoVersao': solicitacaoFornecimento == null ? 0 : parseInt(solicitacaoFornecimento.idContratoVersao),
            'IdTipoSolicitacao': parseInt(idTipoSolicitacao),
            'NumeroProcesso': numeroProcesso != '' ? numeroProcesso : null,
            'NumeroOficio': numeroOficio != '' ? numeroOficio : '',
            'DescricaoSolicitacao': descricao != '' ? descricao : null,
            'Observacao': observacao != '' ? observacao : null,
            'DataSolicitacao': moment((new Date())).add('hours', 2),
            'Endereco': endereco != '' ? endereco : null,
            'Latitude': solicitacaoFornecimento == null ? '0' : solicitacaoFornecimento.latitude,
            'Longitude': solicitacaoFornecimento == null ? '0' : solicitacaoFornecimento.longitude,
            'IdRegiao': idRegiao != '' ? parseInt(idRegiao) : null,
            'IdUsuario': usuario.id,
            'IdContrato': parseInt(idContrato),
            'IdFornecedor': idFornecedor != '' ? parseInt(idFornecedor) : null,
            'IdPrioridade': idPrioridade != '' ? parseInt(idPrioridade) : null,
            'PrazoDias': prazoDias != '' ? parseInt(prazoDias) : null,
            'IdBairro': idBairro != '' ? parseInt(idBairro) : null,
            'TipoSolicitacaoServico': tipoSolicitacaoCompleto != null ? tipoSolicitacaoCompleto.servico : false,
            'NumeroOficioSolicitacaoOrcamento': numeroOficioSolicitacaoOrcamento != '' ? numeroOficioSolicitacaoOrcamento : '',
            'IdAutorizador': idAutorizador != '' ? parseInt(idAutorizador) : null,
            'IdDestinatario': idDestinatario != '' ? parseInt(idDestinatario) : null,
            'DescricaoLocal': descricaoLocal != '' ? descricaoLocal : null,
            'IdInteressado': idInteressado != '' ? parseInt(idInteressado) : null,
            'IdOrgao': idOrgao != '' ? parseInt(idOrgao) : null,
            'Assunto': assunto != '' ? assunto : null,
            'DDD': ddd != '' ? parseInt(ddd) : null,
            'Telefone': telefone != '' ? telefone : null,
            'DDDFixo': dddFixo != '' ? parseInt(dddFixo) : null,
            'TelefoneFixo': telefoneFixo != '' ? telefoneFixo : null,
            'SolicitacaoFornecimentoVersaoApiModel': {
                'Id': solicitacaoFornecimento == null ||
                    solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel == null ? parseInt(0) : solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.id,
                'IdSolicitacaoFornecimento': solicitacaoFornecimento == null ? parseInt(0) : solicitacaoFornecimento.id,
                'Observacao': observacaoVersao,
                'NumeroOrcamento': numeroOrcamento.trim(),
                'DataVersao': moment((new Date())).add('hours', 2),
                'IdUsuario': usuario.id,
            },
            'SolicitacaoFornecimentoVersaoStatusApiModel': {
                'Id': solicitacaoFornecimento == null ||
                    solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel == null ? parseInt(0) : solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel.id,
                'IdSolicitacaoFornecimentoVersao': solicitacaoFornecimento == null ||
                    solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel == null ? parseInt(0) : solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel.idSolicitacaoFornecimentoVersao,
                'IdStatusSolicitacaoFornecimento': solicitacaoFornecimento == null ||
                    solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel == null ? parseInt(0) : solicitacaoFornecimento.solicitacaoFornecimentoVersaoStatusApiModel.idStatusSolicitacaoFornecimento,
                'IdUsuario': usuario.id,
                'DataStatus': moment((new Date())).add('hours', 2),
                'ObservacaoReprovado': observacaoReprovacao,
                'ObservacaoCancelado': '',
                'StatusReprovado': false,
                'StatusAprovado': false,
                'AnaliseConcluida': false
            },
            'ListaSolicitacaoFornecimentoCaracteristicaRespostaApiModel': listaSolicitacaoFornecimentoCaracteristicaResposta
        }

        setDados(solicitacaoCompleta);

        if (id == undefined) {
            dispatch(InserirSolicitacaoFornecimentoRequest(solicitacaoCompleta));
        } else {
            if (solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel != null &&
                numeroOrcamento !== solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento
                && solicitacaoFornecimento.solicitacaoFornecimentoVersaoApiModel.numeroOrcamento.trim() != "") {
                handleShow();
            } else {

                /*if (usuarioCompleto != null && usuarioCompleto.usuarioFornecedor) {
                    toast.error("Só clientes podem editar a solicitação!");
                    return;
                }*/

                dispatch(AlterarSolicitacaoFornecimentoRequest(solicitacaoCompleta));
            }
        }
    }

    function AlterarSolicitacao() {
        if (numeroOrcamento.trim() != "") {
            dispatch(AlterarSolicitacaoFornecimentoRequest(dados));
        } else {
            toast.error('Para editar, o número de orçamento não pode ser vazio.');
        }
        handleClose();
    }

    function CriarNovaVersaoSolicitacao() {

        if (numeroOrcamento != "") {
            let dadosSolicitacaoFornecimentoVersaoApiModel = {
                'Id': 0,
                'IdSolicitacaoFornecimento': solicitacaoFornecimento.id,
                'Observacao': observacaoVersao,
                'NumeroOrcamento': numeroOrcamento,
                'DataVersao': moment((new Date())).add('hours', 2),
                'IdUsuario': usuario.id,
                'SolicitacaoFornecimentoVersaoStatusApiModel': {
                    'Id': 0,
                    'IdSolicitacaoFornecimentoVersao': 0,
                    'IdStatusSolicitacaoFornecimento': 0,
                    'IdUsuario': usuario.id,
                    'DataStatus': moment((new Date())).add('hours', 2),
                    'ObservacaoReprovacao': ''
                }
            }

            dispatch(InserirSolicitacaoFornecimentoVersaoRequest(dadosSolicitacaoFornecimentoVersaoApiModel));

            handleClose();
        } else {
            toast.error('Para a nova versão o número de orçamento não pode ser vazio!');
        }
    }

    function onChangeContrato(e) {
        if (e != null && e != undefined) {
            setIdContrato(e.value);
            dispatch(ListarCompletoContratoFornecedorRequest(e.value));
        }
    }

    function CarregarInputs(e) {
        if (e != null && e != undefined) {
            setIdTipoSolicitacao(e.value);
            dispatch(BuscarTipoSolicitacaoRequest(parseInt(e.value), id != undefined ? id : null));
        }
    }

    function MudarValor(descricao, e, tipo, idCaracteristicaResposta) {

        //console.log(descricao, e, tipo, idCaracteristicaResposta)

        let lista = listaResposta.map(x => {

            if (x.descricao == descricao) {
                return {
                    id: x.id,
                    descricao: x.descricao,
                    valor: e,
                    tipo: tipo,
                    idCaracteristicaResposta: tipo == 'select' ?
                        e != "" ? parseInt(e)
                            : 0 : idCaracteristicaResposta,
                    nulavel: x.nulavel,
                    inputLargo: x.inputLargo
                }
            } else {
                return {
                    id: x.id,
                    descricao: x.descricao,
                    valor: x.valor,
                    tipo: x.tipo,
                    idCaracteristicaResposta: x.idCaracteristicaResposta,
                    nulavel: x.nulavel,
                    inputLargo: x.inputLargo
                }
            }

        })

        setListaResposta(lista);
    }

    function MudarTelefoneInteressado(e) {

        setIdInteressado(e.target.value);

        if (e.target.value == "") {
            setEndereco("");
            setDdd("");
            setTelefone("");
            setDddFixo("");
            setTelefoneFixo("");

        }

        if (listaInteressado != undefined) {
            var interessado = listaInteressado.find(x => x.id == parseInt(e.target.value));
            if (interessado != null) {
                setEndereco(interessado.endereco);
                setDdd(interessado.numeroDdd == null ? '' : interessado.numeroDdd);
                setTelefone(interessado.numeroTelefone == null ? '' : interessado.numeroTelefone);
                setDddFixo(interessado.numeroDddFixo == null ? '' : interessado.numeroDddFixo);
                setTelefoneFixo(interessado.numeroTelefoneFixo == null ? '' : interessado.numeroTelefoneFixo);
            }
        }
    }

    function exportaFotos() {
        dispatch(ListarDadosExportarPDFSolicitacaoFornecimentoRequest({ 'IdSolicitacaoFornecimento': id }))
    }

    function exportaFotosEvento() {
        dispatch(ListarDadosExportarPDFSolicitacaoFornecimentoEventoRequest({ 'IdSolicitacaoFornecimento': id }))
    }

    function KeyPressAssunto(e) {

        var contem = listarSolicitacaoFornecimentoCamposVerificacaoRapida.listaAssunto.filter(n => n == e);
        if (contem.length > 0) {
            setAssuntoRepetido(e)
        } else {
            setAssuntoRepetido('')
        }

    }

    function KeyPressLocal(e) {

        var contem = listarSolicitacaoFornecimentoCamposVerificacaoRapida.listaLocal.filter(n => n == e);
        if (contem.length > 0) {
            setLocalRepetido(e)
        } else {
            setLocalRepetido('')
        }

    }

    function KeyPressNumeroProcesso(e) {

        var contem = listarSolicitacaoFornecimentoCamposVerificacaoRapida.listaNumeroProcesso.filter(n => n == e);
        if (contem.length > 0) {
            setNumeroProcessoRepetido(e)
        } else {
            setNumeroProcessoRepetido('')
        }

    }

    function SelecionarInteressado() {
        handleShowInteressado();
    }

    function SelecionarOrgao() {
        handleShowOrgao();
    }

    function GerarOficio() {
        dispatch(GerarOficioRequest(id, 1))
    }

    function onChangeBairro(e) {
        setIdBairro(e !== null ? e.value.toString() : false);
        if (e != null && listaBairro.filter(m => m.id == e.value).length > 0) {
            setIdRegiao(listaBairro.filter(m => m.id == e.value)[0].idRegiao);
        } else {
            setIdRegiao('');
        }
    }

    function SelecionarDestinatarioDinamico() {
        handleShowDestinatario();
    }

    return (
        <>
            {
                (carregandoTime && carregando && tipoSolicitacaoCompleto != null) || id == undefined ?

                    <Form noValidate onSubmit={handleSubmit}>
                        <div className="tamanhoTela">
                            <Col md={12}>

                                {
                                    tipoSolicitacaoCompleto != undefined && tipoSolicitacaoCompleto != null &&
                                        tipoSolicitacaoCompleto.eventoResposta == true ?
                                        <Row className="mb-3">

                                            <Form.Group as={Col} md={4} controlId="formNumeroOficio">
                                                <Form.Label>Número da Solicitação</Form.Label>
                                                <Form.Control
                                                    style={{ minHeight: '36px' }}
                                                    type="text"
                                                    value={idSolicitacao !== '' ? idSolicitacao : ''}
                                                    disabled
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md={4} controlId="formIdContrato">
                                                <Form.Label>Contrato</Form.Label>
                                                <Select
                                                    id="selectContrato"
                                                    isDisabled={id == undefined ? false : true}
                                                    styles={{
                                                        control: (styles) => ({ ...styles, borderColor: validated && idContrato === "" || idContrato === false ? 'red' : '#C0C0C0' }),
                                                        placeholder: (styles) => ({ ...styles, color: validated && idContrato === "" || idContrato === false ? 'red' : '#C0C0C0' })
                                                    }}
                                                    placeholder="Selecione..."
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    defaultValue={listaContrato != undefined && idContrato ?
                                                        { value: idContrato, label: listaContrato.filter(m => m.id == idContrato)[0].descricao }
                                                        : null
                                                    }
                                                    options={
                                                        listaContrato != undefined ?
                                                            listaContrato.map(m => ({
                                                                value: m.id, label: m.id + " - " + m.descricao
                                                            }))
                                                            :
                                                            null
                                                    }
                                                    onChange={(e) => onChangeContrato(e)}
                                                />
                                                <Form.Control.Feedback type="invalid">O campo Contrato é obrigatório.</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md={4} controlId="formTipoSolicitacao">
                                                <Form.Label>Tipo de Solicitação</Form.Label>
                                                <Select
                                                    styles={{
                                                        control: (styles) => ({ ...styles, borderColor: validated && idTipoSolicitacao === "" || idTipoSolicitacao === false ? 'red' : '#C0C0C0' }),
                                                        placeholder: (styles) => ({ ...styles, color: validated && idTipoSolicitacao === "" || idTipoSolicitacao === false ? 'red' : '#C0C0C0' })
                                                    }}
                                                    id="selectTipo"
                                                    isDisabled={id != undefined ? true : false}
                                                    placeholder="Selecione..."
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    required
                                                    defaultValue={listaTipoSolicitacao != undefined && idTipoSolicitacao ?
                                                        { value: idTipoSolicitacao, label: listaTipoSolicitacao.filter(m => m.id == idTipoSolicitacao)[0].descricao }
                                                        : null
                                                    }
                                                    options={
                                                        listaTipoSolicitacao != undefined && listaTipoSolicitacao != null ?
                                                            id == undefined ?
                                                                listaTipoSolicitacao.filter(x => x.eventoEspecial == false)
                                                                    .filter(y => y.tipoFornecedor == usuario.usuarioFornecedor).sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                        value: m.id, label: m.descricao
                                                                    }))
                                                                :
                                                                listaTipoSolicitacao.map(m => ({
                                                                    value: m.id, label: m.descricao
                                                                }))
                                                            :
                                                            null
                                                    }
                                                    onChange={(e) => CarregarInputs(e)}
                                                />
                                                <Form.Control.Feedback type="invalid">O campo Tipo de Solicitação é obrigatório.</Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>
                                        :
                                        <Row className="mb-3">

                                            <Form.Group as={Col} md={3} controlId="formNumeroOficio">
                                                <Form.Label>Número da Solicitação</Form.Label>
                                                <Form.Control
                                                    style={{ minHeight: '36px' }}
                                                    type="text"
                                                    value={idSolicitacao !== '' ? idSolicitacao : ''}
                                                    disabled
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md={3} controlId="formIdContrato">
                                                <Form.Label>Contrato</Form.Label>
                                                <Select
                                                    id="selectContrato"
                                                    isDisabled={id == undefined ? false : true}
                                                    styles={{
                                                        control: (styles) => ({ ...styles, borderColor: validated && idContrato === "" || idContrato === false ? 'red' : '#C0C0C0' }),
                                                        placeholder: (styles) => ({ ...styles, color: validated && idContrato === "" || idContrato === false ? 'red' : '#C0C0C0' })
                                                    }}
                                                    placeholder="Selecione..."
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    defaultValue={listaContrato != undefined && idContrato ?
                                                        { value: idContrato, label: listaContrato.filter(m => m.id == idContrato)[0].descricao }
                                                        : null
                                                    }
                                                    options={
                                                        listaContrato != undefined ?
                                                            listaContrato.map(m => ({
                                                                value: m.id, label: m.id + " - " + m.descricao
                                                            }))
                                                            :
                                                            null
                                                    }
                                                    onChange={(e) => onChangeContrato(e)}
                                                />
                                                <Form.Control.Feedback type="invalid">O campo Contrato é obrigatório.</Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md={3} controlId="formTipoSolicitacao">
                                                <Form.Label>Tipo de Solicitação</Form.Label>
                                                <Select
                                                    styles={{
                                                        control: (styles) => ({ ...styles, borderColor: validated && idTipoSolicitacao === "" || idTipoSolicitacao === false ? 'red' : '#C0C0C0' }),
                                                        placeholder: (styles) => ({ ...styles, color: validated && idTipoSolicitacao === "" || idTipoSolicitacao === false ? 'red' : '#C0C0C0' })
                                                    }}
                                                    id="selectTipo"
                                                    isDisabled={id != undefined ? true : false}
                                                    placeholder="Selecione..."
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    required
                                                    defaultValue={listaTipoSolicitacao != undefined && idTipoSolicitacao ?
                                                        { value: idTipoSolicitacao, label: listaTipoSolicitacao.filter(m => m.id == idTipoSolicitacao)[0].descricao }
                                                        : null
                                                    }
                                                    options={
                                                        listaTipoSolicitacao != undefined && listaTipoSolicitacao != null ?
                                                            id == undefined ?
                                                                listaTipoSolicitacao.filter(x => x.eventoEspecial == false)
                                                                    .filter(y => y.tipoFornecedor == usuario.usuarioFornecedor).sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                        value: m.id, label: m.descricao
                                                                    }))
                                                                :
                                                                listaTipoSolicitacao.map(m => ({
                                                                    value: m.id, label: m.descricao
                                                                }))
                                                            :
                                                            null
                                                    }
                                                    onChange={(e) => CarregarInputs(e)}
                                                />
                                                <Form.Control.Feedback type="invalid">O campo Tipo de Solicitação é obrigatório.</Form.Control.Feedback>
                                            </Form.Group>


                                            <Form.Group as={Col} md={3} controlId="formNumeroOficio">
                                                <Form.Label>Número Oficio</Form.Label>
                                                <Form.Control
                                                    style={{ minHeight: '36px' }}
                                                    type="text"
                                                    value={numeroOficio}
                                                    disabled
                                                />
                                            </Form.Group>




                                        </Row>
                                }
                                {
                                    idTipoSolicitacao != "" ?

                                        tipoSolicitacaoCompleto != undefined && tipoSolicitacaoCompleto != null &&
                                            tipoSolicitacaoCompleto.inputServico ?
                                            <div>
                                                <Row className="mb-3">
                                                    {
                                                        id == undefined ?
                                                            <Form.Group as={Col} md={4}>
                                                                <Form.Label>Fornecedor</Form.Label>
                                                                <Select
                                                                    styles={{
                                                                        control: (styles) => ({ ...styles, borderColor: validated && idFornecedor === "" || idFornecedor === false ? 'red' : '#C0C0C0' }),
                                                                        placeholder: (styles) => ({ ...styles, color: validated && idFornecedor === "" || idFornecedor === false ? 'red' : '#C0C0C0' })
                                                                    }}
                                                                    id="selectFornecedor"
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    defaultValue={
                                                                        idFornecedor != "" && listaFornecedor != undefined && listaFornecedor != null
                                                                            ?
                                                                            listaFornecedor.filter(m => m.id == idFornecedor)[0].nomeFornecedor
                                                                            : (listaContratoFornecedor != undefined && listaContratoFornecedor != null && listaContratoFornecedor.length == 1) && idContrato != ''
                                                                                ?
                                                                                setIdFornecedor(listaContratoFornecedor[0].idFornecedor)
                                                                                :
                                                                                0
                                                                    }
                                                                    disabled={idContrato == '' ? true : false}
                                                                    required
                                                                    options={
                                                                        listaContratoFornecedor != undefined && listaContratoFornecedor.length > 0 ?
                                                                            listaContratoFornecedor.filter(x => x.idContrato == idContrato &&
                                                                                listaFornecedor.filter(y => y.id == x.idFornecedor))
                                                                                .map(m =>
                                                                                    m.fornecedor !== null ?
                                                                                        ({
                                                                                            value: m.idFornecedor, label: m.fornecedor.nomeFornecedor
                                                                                        })
                                                                                        :
                                                                                        null
                                                                                )
                                                                            :
                                                                            null
                                                                    }
                                                                    onChange={(e) => setIdFornecedor(e !== null ? e.value.toString() : false)}
                                                                //isInvalid={validated && (idFornecedor === "" || idFornecedor === false) ? true : false}
                                                                //isValid={validated && idFornecedor !== "" ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Fornecedor é obrigatório.</Form.Control.Feedback>
                                                            </Form.Group>
                                                            :
                                                            <Form.Group as={Col} md={4}>
                                                                <Form.Label>Fornecedor</Form.Label>
                                                                <Select
                                                                    styles={{
                                                                        control: (styles) => ({ ...styles, borderColor: validated && idFornecedor === "" || idFornecedor === false ? 'red' : '#C0C0C0' }),
                                                                        placeholder: (styles) => ({ ...styles, color: validated && idFornecedor === "" || idFornecedor === false ? 'red' : '#C0C0C0' })
                                                                    }}
                                                                    id="selectFornecedor"
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    defaultInputValue={
                                                                        idFornecedor != "" && listaFornecedor != undefined && listaFornecedor != null && listaFornecedor.filter(m => m.id == idFornecedor).length > 0
                                                                            ?
                                                                            listaFornecedor.filter(m => m.id == idFornecedor)[0].nomeFornecedor
                                                                            : (listaContratoFornecedor != undefined && listaContratoFornecedor != null && listaContratoFornecedor.length == 1) && idContrato != ''
                                                                                ?
                                                                                setIdFornecedor(listaContratoFornecedor[0].idFornecedor)
                                                                                :
                                                                                0
                                                                    }
                                                                    disabled={idContrato == '' ? true : false}
                                                                    required
                                                                    options={
                                                                        listaContratoFornecedor != undefined && listaContratoFornecedor.length > 0 ?
                                                                            listaContratoFornecedor.filter(x => x.idContrato == idContrato &&
                                                                                listaFornecedor.filter(y => y.id == x.idFornecedor))
                                                                                .map(m =>
                                                                                    m.fornecedor !== null ?
                                                                                        ({
                                                                                            value: m.idFornecedor, label: m.fornecedor.nomeFornecedor
                                                                                        })
                                                                                        :
                                                                                        null
                                                                                )
                                                                            :
                                                                            null
                                                                    }
                                                                    onChange={(e) => setIdFornecedor(e !== null ? e.value.toString() : false)}
                                                                //isInvalid={validated && (idFornecedor === "" || idFornecedor === false) ? true : false}
                                                                //isValid={validated && idFornecedor !== "" ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Fornecedor é obrigatório.</Form.Control.Feedback>
                                                            </Form.Group>
                                                    }

                                                    <Form.Group as={Col} md={4} controlId="formRegiao">
                                                        <Form.Label>Prioridade</Form.Label>
                                                        <Select
                                                            styles={{
                                                                control: (styles) => ({ ...styles, borderColor: validated && idPrioridade === "" || idPrioridade === false ? 'red' : '#C0C0C0' }),
                                                                placeholder: (styles) => ({ ...styles, color: validated && idPrioridade === "" || idPrioridade === false ? 'red' : '#C0C0C0' })
                                                            }}
                                                            placeholder="Selecione..."
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            defaultValue={listaPrioridade != undefined && idPrioridade ?
                                                                { value: idPrioridade, label: listaPrioridade.filter(m => m.id == idPrioridade)[0].descricao }
                                                                : null
                                                            }
                                                            required
                                                            options={
                                                                listaPrioridade != undefined ?
                                                                    [...listaPrioridade].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                        value: m.id, label: m.descricao
                                                                    }))
                                                                    :
                                                                    null
                                                            }
                                                            onChange={(e) => setIdPrioridade(e !== null ? e.value.toString() : false)}

                                                        />
                                                        <Form.Control.Feedback type="invalid">O campo Prioridade é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={4} controlId="formEndereco">
                                                        <Form.Label>Número Orçamento</Form.Label>
                                                        <Form.Control
                                                            style={{ minHeight: '36px' }}
                                                            disabled={true}
                                                            type="text"
                                                            value={numeroOrcamento}
                                                            onChange={e => setNumeroOrcamento(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md={6} controlId="formAssunto">
                                                        <Form.Label>Assunto</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            as="textarea"
                                                            value={assunto != null && assunto.trim() == '' ? '' : assunto}
                                                            onChange={e => {
                                                                setAssunto(e.target.value);
                                                                KeyPressAssunto(e.target.value);
                                                            }}
                                                            required
                                                            maxLength="200"
                                                            isInvalid={validated && assunto.trim() === '' ? true : false}
                                                            isValid={validated && assunto.trim() !== '' ? true : false}
                                                        />
                                                        <Form.Control.Feedback type="invalid">O campo Assunto é obrigatório.</Form.Control.Feedback>
                                                        {
                                                            assuntoRepetido != '' ?
                                                                <Alert variant={`danger`} style={{ width: '100%' }}>
                                                                    Já contém esse assunto cadastrado
                                                                </Alert>
                                                                :
                                                                ''
                                                        }
                                                    </Form.Group>

                                                    <Form.Group as={Col} md={6} controlId="formDescricaoLocal">
                                                        <Form.Label>Local</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            as="textarea"
                                                            value={descricaoLocal != null && descricaoLocal.trim() == '' ? '' : descricaoLocal}
                                                            onChange={e => {
                                                                setDescricaoLocal(e.target.value);
                                                                KeyPressLocal(e.target.value);
                                                            }}
                                                            required
                                                            maxLength="645"
                                                            isInvalid={validated && descricaoLocal.trim() === '' ? true : false}
                                                            isValid={validated && descricaoLocal.trim() !== '' ? true : false}
                                                        />
                                                        <Form.Control.Feedback type="invalid">O campo Local é obrigatório.</Form.Control.Feedback>
                                                        {
                                                            localRepetido != '' ?
                                                                <Alert variant={`danger`} style={{ width: '100%' }}>
                                                                    Já contém esse local cadastrado
                                                                </Alert>
                                                                :
                                                                ''
                                                        }
                                                    </Form.Group>
                                                </Row>

                                                {
                                                    tipoSolicitacaoCompleto != null &&
                                                        tipoSolicitacaoCompleto.tipoFornecedor ?
                                                        <Row className="mb-3">

                                                            <Form.Group as={Col} md={3} controlId="formBairro">
                                                                <Form.Label>Bairro</Form.Label>
                                                                <Select
                                                                    styles={{
                                                                        control: (styles) => ({ ...styles, borderColor: validated && idBairro === "" || idBairro === false ? 'red' : '#C0C0C0' }),
                                                                        placeholder: (styles) => ({ ...styles, color: validated && idBairro === "" || idBairro === false ? 'red' : '#C0C0C0' })
                                                                    }}
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    required
                                                                    defaultValue={listaBairro != undefined && idBairro ?
                                                                        { value: idBairro, label: listaBairro.filter(m => m.id == idBairro)[0].descricao }
                                                                        : null
                                                                    }
                                                                    options={
                                                                        listaBairro != undefined ?
                                                                            [...listaBairro].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                                value: m.id, label: m.descricao
                                                                            }))
                                                                            :
                                                                            null
                                                                    }
                                                                    onChange={e => onChangeBairro(e)}
                                                                //isInvalid={validated && (idBairro === "" || idBairro === false) ? true : false}
                                                                //isValid={validated && idBairro !== "" ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Bairro é obrigatório.</Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md={3} controlId="formRegiao">
                                                                <Form.Label>Região</Form.Label>
                                                                <Select
                                                                    styles={{
                                                                        control: (styles) => ({ ...styles, borderColor: validated && idRegiao === "" || idRegiao === false ? 'red' : '#C0C0C0' }),
                                                                        placeholder: (styles) => ({ ...styles, color: validated && idRegiao === "" || idRegiao === false ? 'red' : '#C0C0C0' })
                                                                    }}
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    required
                                                                    value={listaRegiao != undefined && idRegiao ?
                                                                        { value: idRegiao, label: listaRegiao.filter(m => m.id == idRegiao)[0].descricao }
                                                                        : null
                                                                    }
                                                                    options={
                                                                        listaRegiao != undefined ?
                                                                            [...listaRegiao].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                                value: m.id, label: m.descricao
                                                                            }))
                                                                            :
                                                                            null
                                                                    }
                                                                    onChange={(e) => setIdRegiao(e !== null ? e.value.toString() : false)}
                                                                //isInvalid={validated && (idRegiao === "" || idRegiao === false) ? true : false}
                                                                //isValid={validated && idRegiao !== "" ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Região é obrigatório.</Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md={3} controlId="formIdAutorizador">
                                                                <Form.Label>Autorizador</Form.Label>
                                                                <Select
                                                                    styles={{
                                                                        control: (styles) => ({ ...styles, borderColor: validated && idAutorizador === "" || idAutorizador === false ? 'red' : '#C0C0C0' }),
                                                                        placeholder: (styles) => ({ ...styles, color: validated && idAutorizador === "" || idAutorizador === false ? 'red' : '#C0C0C0' })
                                                                    }}
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    required
                                                                    defaultValue={listaUsuario != undefined && idAutorizador ?
                                                                        { value: idAutorizador, label: listaUsuario.filter(m => m.id == idAutorizador)[0].nome }
                                                                        : null
                                                                    }
                                                                    options={
                                                                        listaUsuario != undefined && tipoSolicitacaoCompleto != null ?
                                                                            listaUsuario.filter(y => y.usuarioFornecedor).sort((a, b) => a.nome.localeCompare(b.nome)).map(m => ({
                                                                                value: m.id, label: m.nome
                                                                            }))
                                                                            :
                                                                            null
                                                                    }
                                                                    onChange={(e) => setIdAutorizador(e !== null ? e.value.toString() : false)}
                                                                //isInvalid={validated && idAutorizador === "" ? true : false}
                                                                //isValid={validated && idAutorizador !== "" ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Autorizador é obrigatório.</Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md={3} controlId="formIdDestinatario">
                                                                <Form.Label>Destinatário</Form.Label>
                                                                <Select
                                                                    styles={{
                                                                        control: (styles) => ({ ...styles, borderColor: validated && idDestinatario === "" || idDestinatario === false ? 'red' : '#C0C0C0' }),
                                                                        placeholder: (styles) => ({ ...styles, color: validated && idDestinatario === "" || idDestinatario === false ? 'red' : '#C0C0C0' })
                                                                    }}
                                                                    placeholder="Selecione..."
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    required
                                                                    defaultValue={listaUsuario != undefined && idDestinatario ?
                                                                        { value: idDestinatario, label: listaUsuario.filter(m => m.id == idDestinatario)[0].nome }
                                                                        : null
                                                                    }
                                                                    options={
                                                                        listaUsuario != undefined && tipoSolicitacaoCompleto != null ?
                                                                            listaUsuario.filter(y => !y.usuarioFornecedor).sort((a, b) => a.nome.localeCompare(b.nome)).map(m => ({
                                                                                value: m.id, label: m.nome
                                                                            }))
                                                                            :
                                                                            null
                                                                    }
                                                                    onChange={(e) => setIdDestinatario(e !== null ? e.value.toString() : false)}
                                                                //isInvalid={validated && (idDestinatario === "" || idDestinatario === false) ? true : false}
                                                                //isValid={validated && idDestinatario !== "" ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Destinatário é obrigatório.</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Row>
                                                        :

                                                        tipoSolicitacaoCompleto != null && tipoSolicitacaoCompleto.semOficio == true ?
                                                            <Row className="mb-3">

                                                                <Form.Group as={Col} md={6} controlId="formBairro">
                                                                    <Form.Label>Bairro</Form.Label>
                                                                    <Select
                                                                        styles={{
                                                                            control: (styles) => ({ ...styles, borderColor: validated && idBairro === "" || idBairro === false ? 'red' : '#C0C0C0' }),
                                                                            placeholder: (styles) => ({ ...styles, color: validated && idBairro === "" || idBairro === false ? 'red' : '#C0C0C0' })
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        required
                                                                        defaultValue={listaBairro != undefined && idBairro ?
                                                                            { value: idBairro, label: listaBairro.filter(m => m.id == idBairro)[0].descricao }
                                                                            : null
                                                                        }
                                                                        options={
                                                                            listaBairro != undefined ?
                                                                                [...listaBairro].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                                    value: m.id, label: m.descricao
                                                                                }))
                                                                                :
                                                                                null
                                                                        }
                                                                        onChange={e => onChangeBairro(e)}
                                                                    //isInvalid={validated && idBairro === "" ? true : false}
                                                                    //isValid={validated && idBairro !== "" ? true : false}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">O campo Bairro é obrigatório.</Form.Control.Feedback>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md={6} controlId="formRegiao">
                                                                    <Form.Label>Região</Form.Label>
                                                                    <Select
                                                                        styles={{
                                                                            control: (styles) => ({ ...styles, borderColor: validated && idRegiao === "" || idRegiao === false ? 'red' : '#C0C0C0' }),
                                                                            placeholder: (styles) => ({ ...styles, color: validated && idRegiao === "" || idRegiao === false ? 'red' : '#C0C0C0' })
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        required
                                                                        value={listaRegiao != undefined && idRegiao ?
                                                                            { value: idRegiao, label: listaRegiao.filter(m => m.id == idRegiao)[0].descricao }
                                                                            : null
                                                                        }
                                                                        options={
                                                                            listaRegiao != undefined ?
                                                                                [...listaRegiao].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                                    value: m.id, label: m.descricao
                                                                                }))
                                                                                :
                                                                                null
                                                                        }
                                                                        onChange={(e) => setIdRegiao(e !== null ? e.value.toString() : false)}
                                                                    //isInvalid={validated && idRegiao === "" ? true : false}
                                                                    //isValid={validated && idRegiao !== "" ? true : false}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">O campo Região é obrigatório.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Row>
                                                            :
                                                            <Row className="mb-3">

                                                                <Form.Group as={Col} md={3} controlId="formBairro">
                                                                    <Form.Label>Bairro</Form.Label>
                                                                    <Select
                                                                        styles={{
                                                                            control: (styles) => ({ ...styles, borderColor: validated && idBairro === "" || idBairro === false ? 'red' : '#C0C0C0' }),
                                                                            placeholder: (styles) => ({ ...styles, color: validated && idBairro === "" || idBairro === false ? 'red' : '#C0C0C0' })
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        required
                                                                        defaultValue={listaBairro != undefined && idBairro ?
                                                                            { value: idBairro, label: listaBairro.filter(m => m.id == idBairro)[0].descricao }
                                                                            : null
                                                                        }
                                                                        options={
                                                                            listaBairro != undefined ?
                                                                                [...listaBairro].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                                    value: m.id, label: m.descricao
                                                                                }))
                                                                                :
                                                                                null
                                                                        }
                                                                        onChange={e => onChangeBairro(e)}
                                                                    //isInvalid={validated && idBairro === "" ? true : false}
                                                                    //isValid={validated && idBairro !== "" ? true : false}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">O campo Bairro é obrigatório.</Form.Control.Feedback>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md={3} controlId="formRegiao">
                                                                    <Form.Label>Região</Form.Label>
                                                                    <Select
                                                                        styles={{
                                                                            control: (styles) => ({ ...styles, borderColor: validated && idRegiao === "" || idRegiao === false ? 'red' : '#C0C0C0' }),
                                                                            placeholder: (styles) => ({ ...styles, color: validated && idRegiao === "" || idRegiao === false ? 'red' : '#C0C0C0' })
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        required
                                                                        value={listaRegiao != undefined && idRegiao ?
                                                                            { value: idRegiao, label: listaRegiao.filter(m => m.id == idRegiao)[0].descricao }
                                                                            : null
                                                                        }
                                                                        options={
                                                                            listaRegiao != undefined ?
                                                                                [...listaRegiao].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                                    value: m.id, label: m.descricao
                                                                                }))
                                                                                :
                                                                                null
                                                                        }
                                                                        onChange={(e) => setIdRegiao(e !== null ? e.value.toString() : false)}
                                                                    //isInvalid={validated && idRegiao === "" ? true : false}
                                                                    //isValid={validated && idRegiao !== "" ? true : false}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">O campo Região é obrigatório.</Form.Control.Feedback>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md={3} controlId="formIdAutorizador">
                                                                    <Form.Label>Autorizador</Form.Label>
                                                                    <Select
                                                                        styles={{
                                                                            control: (styles) => ({ ...styles, borderColor: validated && idAutorizador === "" || idAutorizador === false ? 'red' : '#C0C0C0' }),
                                                                            placeholder: (styles) => ({ ...styles, color: validated && idAutorizador === "" || idAutorizador === false ? 'red' : '#C0C0C0' })
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        required
                                                                        defaultValue={listaUsuario != undefined && idAutorizador ?
                                                                            { value: idAutorizador, label: listaUsuario.filter(m => m.id == idAutorizador)[0].nome }
                                                                            : null
                                                                        }
                                                                        options={
                                                                            listaUsuario != undefined && tipoSolicitacaoCompleto != null ?
                                                                                listaUsuario.filter(y => y.usuarioFornecedor == false).sort((a, b) => a.nome.localeCompare(b.nome)).map(m => ({
                                                                                    value: m.id, label: m.nome
                                                                                }))
                                                                                :
                                                                                null
                                                                        }
                                                                        onChange={(e) => setIdAutorizador(e !== null ? e.value.toString() : false)}
                                                                    //isInvalid={validated && (idAutorizador === "" || idAutorizador === false) ? true : false}
                                                                    //isValid={validated && idAutorizador !== "" ? true : false}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">O campo Autorizador é obrigatório.</Form.Control.Feedback>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md={3} controlId="formIdDestinatario">
                                                                    <Form.Label>Destinatário</Form.Label>
                                                                    <Select
                                                                        styles={{
                                                                            control: (styles) => ({ ...styles, borderColor: validated && idDestinatario === "" || idDestinatario === false ? 'red' : '#C0C0C0' }),
                                                                            placeholder: (styles) => ({ ...styles, color: validated && idDestinatario === "" || idDestinatario === false ? 'red' : '#C0C0C0' })
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        required
                                                                        defaultValue={listaUsuario != undefined && idDestinatario ?
                                                                            { value: idDestinatario, label: listaUsuario.filter(m => m.id == idDestinatario)[0].nome }
                                                                            : null
                                                                        }
                                                                        options={
                                                                            listaUsuario != undefined && tipoSolicitacaoCompleto != null ?
                                                                                listaUsuario.filter(y => y.usuarioFornecedor).sort((a, b) => a.nome.localeCompare(b.nome)).map(m => ({
                                                                                    value: m.id, label: m.nome
                                                                                }))
                                                                                :
                                                                                null
                                                                        }
                                                                        onChange={(e) => setIdDestinatario(e !== null ? e.value.toString() : false)}
                                                                    //isInvalid={validated && idDestinatario === "" ? true : false}
                                                                    //isValid={validated && idDestinatario !== "" ? true : false}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">O campo Destinatário é obrigatório.</Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Row>

                                                }

                                                <Row className="mb-3">

                                                    <Form.Group as={Col} md={4} controlId="formPrazoDias">
                                                        <Form.Label>Prazo (em dias)</Form.Label>
                                                        <Form.Control
                                                            style={{ minHeight: '36px' }}
                                                            type="number"
                                                            disabled
                                                            value={prazoDias}
                                                            onChange={e => setPrazoDias(e.target.value)}
                                                            onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                                        //required
                                                        //maxLength="10"
                                                        //isInvalid={validated && prazoDias === "" ? true : false}
                                                        //isValid={validated && prazoDias !== "" ? true : false}
                                                        />
                                                        <Form.Control.Feedback type="invalid">O campo Prazo é obrigatório.</Form.Control.Feedback>
                                                    </Form.Group>

                                                    {
                                                        tipoSolicitacaoCompleto != null &&
                                                            tipoSolicitacaoCompleto.tipoFornecedor &&
                                                            tipoSolicitacaoCompleto.id == 129 ?

                                                            <Form.Group as={Col} md={3} controlId="formNumeroProcesso">
                                                                <Form.Label>Número Processo</Form.Label>
                                                                <Form.Control
                                                                    style={{ minHeight: '36px' }}
                                                                    type="text"
                                                                    value={numeroProcesso}
                                                                    onChange={e => {
                                                                        setNumeroProcesso(e.target.value);
                                                                        KeyPressNumeroProcesso(e.target.value);
                                                                    }}
                                                                    maxLength="50"
                                                                    isInvalid={validated && numeroProcesso != '' && numeroProcesso != null && numeroProcesso.trim().length > 50 ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Número Processo só pode conter 50 caracteres.</Form.Control.Feedback>
                                                                {
                                                                    numeroProcessoRepetido != '' ?
                                                                        <Alert variant={`danger`} style={{ width: '100%' }}>
                                                                            Já contém esse número de processo cadastrado
                                                                        </Alert>
                                                                        :
                                                                        ''
                                                                }
                                                            </Form.Group>
                                                            :
                                                            <Form.Group as={Col} md={3} controlId="formNumeroProcesso">
                                                                <Form.Label>Número Processo</Form.Label>
                                                                <Form.Control
                                                                    style={{ minHeight: '36px' }}
                                                                    type="text"
                                                                    value={numeroProcesso}
                                                                    onChange={e => {
                                                                        setNumeroProcesso(e.target.value);
                                                                        KeyPressNumeroProcesso(e.target.value);
                                                                    }}
                                                                    required
                                                                    maxLength="50"
                                                                    isInvalid={validated && numeroProcesso.trim() === "" ? true : false}
                                                                    isValid={validated && numeroProcesso.trim() !== "" ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Número Processo é obrigatório.</Form.Control.Feedback>
                                                                {
                                                                    numeroProcessoRepetido != '' ?
                                                                        <Alert variant={`danger`} style={{ width: '100%' }}>
                                                                            Já contém esse número de processo cadastrado
                                                                        </Alert>
                                                                        :
                                                                        ''
                                                                }
                                                            </Form.Group>
                                                    }

                                                    <Form.Group as={Col} md={3} controlId="formOrgao">
                                                        <Form.Label>Órgão</Form.Label>
                                                        <Form.Control
                                                            style={{ minHeight: '36px' }}
                                                            type="text"
                                                            value={orgao}
                                                            disabled
                                                            isInvalid={validated && (orgao === "" || orgao === false) ? true : false}
                                                            isValid={validated && orgao !== "" ? true : false}
                                                        //isInvalid={validated && numeroProcesso != '' && numeroProcesso != null && numeroProcesso.trim() === "" ? true : false}
                                                        //isValid={validated && numeroProcesso != '' && numeroProcesso != null && numeroProcesso.trim() !== "" ? true : false}
                                                        />
                                                        {/* <Select
                                                            styles={{
                                                                control: (styles) => ({ ...styles, borderColor: validated && orgao === "" || orgao === false ? 'red' : '#C0C0C0' }),
                                                                placeholder: (styles) => ({ ...styles, color: validated && orgao === "" || orgao === false ? 'red' : '#C0C0C0' })
                                                            }}
                                                            placeholder="Selecione..."
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            required
                                                            defaultValue={listaOrgao != undefined && orgao ?
                                                                { value: orgao, label: listaOrgao.filter(m => m.id == orgao)[0].descricao }
                                                                : null
                                                            }
                                                            options={
                                                                listaOrgao != undefined && listaOrgao.length > 0 ?
                                                                    listaOrgao.filter(x => x.ativo == true).sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                        value: m.id, label: m.descricao
                                                                    }))
                                                                    :
                                                                    null
                                                            }
                                                            onChange={(e) => setOrgao(e !== null ? e.value.toString() : false)}
                                                        isInvalid={validated && (orgao === "" || orgao === false) ? true : false}
                                                        isValid={validated && orgao !== "" ? true : false}
                                                        /> */}
                                                        <Form.Control.Feedback type="invalid">O campo Órgão é obrigatório.</Form.Control.Feedback>

                                                    </Form.Group>
                                                    <Form.Group as={Col} md={2} controlId="formSelecionarOrgao">
                                                        <Form.Label style={{ visibility: 'hidden' }}>.</Form.Label>
                                                        <Button variant="success" style={{ width: '100%' }} onClick={SelecionarOrgao}>Selecionar</Button>
                                                    </Form.Group>
                                                </Row>
                                                {
                                                    tipoSolicitacaoCompleto != null && tipoSolicitacaoCompleto.semOficio == true ?

                                                        ''

                                                        :

                                                        <Row>

                                                            {/*<Form.Group as={Col} md={6} controlId="formDescricao">
                                                        <Form.Label>Descrição</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={descricao}
                                                            onChange={e => setDescricao(e.target.value)}
                                                            required
                                                            maxLength="400"
                                                            isInvalid={validated && descricao.trim() === "" ? true : false}
                                                            isValid={validated && descricao.trim() !== "" ? true : false}
                                                        />
                                                        <Form.Control.Feedback type="invalid">O campo Descrição é obrigatório.</Form.Control.Feedback>
                                                        </Form.Group>*/}

                                                            <Form.Group as={Col} md={6} controlId="formDescricao">
                                                                <Form.Label>Observação</Form.Label>
                                                                <Form.Control
                                                                    style={{ minHeight: '36px' }}
                                                                    type="text"
                                                                    value={observacao}
                                                                    onChange={e => setObservacao(e.target.value)}
                                                                    maxLength="500"
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Descrição é obrigatório.</Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md={4} controlId="formIdInteressado">
                                                                {/*<Form.Select
                                                            onChange={(e) => MudarTelefoneInteressado(e)}
                                                            value={idInteressado}
                                                            required
                                                            isInvalid={validated && idInteressado === "" ? true : false}
                                                            isValid={validated && idInteressado !== "" ? true : false}>
                                                            <option value="">Selecione...</option>
                                                            {
                                                                listaInteressado != undefined ?
                                                                    listaInteressado.map(m => (
                                                                        <option value={m.id} key={m.id}>{m.nome}</option>
                                                                    ))
                                                                    :
                                                                    null
                                                            }
                                                        </Form.Select>*/}
                                                                <Form.Label>Interessado</Form.Label>
                                                                <Form.Control
                                                                    style={{ minHeight: '36px' }}
                                                                    type="text"
                                                                    value={interessado}
                                                                    disabled
                                                                    required
                                                                    maxLength="50"
                                                                //isInvalid={validated && numeroProcesso != '' && numeroProcesso != null && numeroProcesso.trim() === "" ? true : false}
                                                                //isValid={validated && numeroProcesso != '' && numeroProcesso != null && numeroProcesso.trim() !== "" ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Interessado é obrigatório.</Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md={2} controlId="formIdInteressado">
                                                                <Form.Label style={{ visibility: 'hidden' }}>.</Form.Label>
                                                                <Button variant="success" style={{ width: '100%' }} onClick={SelecionarInteressado}>Selecionar</Button>
                                                            </Form.Group>

                                                        </Row>
                                                }
                                                <br />

                                                {
                                                    tipoSolicitacaoCompleto != null && tipoSolicitacaoCompleto.semOficio == true ?
                                                        ''
                                                        :
                                                        <Row className="mb-3">

                                                            <Form.Group as={Col} md={6} controlId="formEndereco">
                                                                <Form.Label>Endereço Interessado</Form.Label>
                                                                <Form.Control
                                                                    style={{ minHeight: '36px' }}
                                                                    type="text"
                                                                    value={endereco}
                                                                    disabled
                                                                    onChange={(e) => setEndereco(e.target.value)}
                                                                    required
                                                                    maxLength="400"
                                                                    isInvalid={validated && endereco.trim() === "" ? true : false}
                                                                    isValid={validated && endereco.trim() !== "" ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">O campo Endereço é obrigatório.</Form.Control.Feedback>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md={1} controlId="formDDD">
                                                                <Form.Label>DDD Interessado</Form.Label>
                                                                <Form.Control
                                                                    style={{ minHeight: '36px' }}
                                                                    as={InputMask}
                                                                    disabled
                                                                    mask='99'
                                                                    value={ddd}
                                                                    onChange={(e) => setDdd(e.target.value)}
                                                                //isInvalid={validated && (ddd == undefined || ddd == null || ddd == '') ? false :
                                                                //  validated && !/^\d{2}$/.test(String(ddd)) ? true : false}
                                                                // isValid={validated && (ddd == undefined || ddd == null || ddd == '') ? true :
                                                                //     validated && /^\d{2}$/.test(String(ddd)) ? true : false}
                                                                />
                                                                {/*<Form.Control.Feedback type="invalid">O campo DDD esta com formato invalido.</Form.Control.Feedback>*/}
                                                            </Form.Group>

                                                            <Form.Group as={Col} md={2} controlId="formTelefone">
                                                                <Form.Label>Telefone Celular Interessado</Form.Label><br />
                                                                <Form.Control
                                                                    style={{ minHeight: '36px' }}
                                                                    as={InputMask}
                                                                    disabled
                                                                    mask='99999 9999'
                                                                    value={telefone}
                                                                    onChange={(e) => setTelefone(e.target.value)}
                                                            /*isInvalid={validated && (telefone == undefined || telefone == null || telefone == '') ? false :
                                                                validated && (!/^\d{1}\d{4} \d{4}$/.test(String(telefone)))
                                                                    && (!/^\d{1}\d{4}\d{4}$/.test(String(telefone)))
                                                                    ? true : false}
                                                            isValid={validated && (telefone == undefined || telefone == null || telefone == '') ? true :
                                                                validated && (/^\d{1}\d{4} \d{4}$/.test(String(telefone)))
                                                                    || (/^\d{1}\d{4}\d{4}$/.test(String(telefone)))
                                                                    ? true : false} */>
                                                                </Form.Control>
                                                                {/*<Form.Control.Feedback type="invalid">O campo Telefone esta com formato invalido.</Form.Control.Feedback>*/}
                                                            </Form.Group>

                                                            <Form.Group as={Col} md={1} controlId="formDDDFixo">
                                                                <Form.Label>DDD Fixo Interessado</Form.Label>
                                                                <Form.Control
                                                                    style={{ minHeight: '36px' }}
                                                                    as={InputMask}
                                                                    disabled
                                                                    mask='99'
                                                                    value={dddFixo}
                                                                    onChange={(e) => setDddFixo(e.target.value)}
                                                                //isInvalid={validated && (dddFixo == undefined || dddFixo == null || dddFixo == '') ? false :
                                                                //   validated && !/^\d{2}$/.test(String(dddFixo)) ? true : false}
                                                                // isValid={validated && (dddFixo == undefined || dddFixo == null || dddFixo == '') ? true :
                                                                //   validated && /^\d{2}$/.test(String(dddFixo)) ? true : false}
                                                                />
                                                                {/*<Form.Control.Feedback type="invalid">O campo DDD Fixo esta com formato invalido.</Form.Control.Feedback>*/}
                                                            </Form.Group>

                                                            <Form.Group as={Col} md={2} controlId="formTelefoneFixo">
                                                                <Form.Label>Telefone Fixo Interessado</Form.Label><br />
                                                                <Form.Control
                                                                    style={{ minHeight: '36px' }}
                                                                    as={InputMask}
                                                                    disabled
                                                                    mask='9999 9999'
                                                                    value={telefoneFixo}
                                                                    onChange={(e) => setTelefoneFixo(e.target.value)}
                                                                // isInvalid={validated && (telefoneFixo == undefined || telefoneFixo == null || telefoneFixo == '') ? false :
                                                                //     validated && !/^\d{4} \d{4}$/.test(String(telefoneFixo)) ? true : false}
                                                                // isValid={validated && (telefoneFixo == undefined || telefoneFixo == null || telefoneFixo == '') ? true :
                                                                //    validated && (/^\d{4} \d{4}$/.test(String(telefoneFixo))) ? true : false} 
                                                                >
                                                                </Form.Control>
                                                                {/* <Form.Control.Feedback type="invalid">O campo Telefone Fixo esta com formato invalido.</Form.Control.Feedback> */}
                                                            </Form.Group>
                                                            <br/>
                                                            <Form.Group as={Col} md={2} controlId="formTelefoneFixo">
                                                                <Form.Label>Número Oficio (Origem)</Form.Label><br />
                                                                <Form.Control
                                                                    style={{ minHeight: '36px' }}
                                                                    maxLength="24"
                                                                    value={numeroOficioSolicitacaoOrcamento}
                                                                    onChange={(e) => setNumeroOficioSolicitacaoOrcamento(e.target.value)}
                                                                >
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Row>
                                                }
                                            </div>
                                            :
                                            tipoSolicitacaoCompleto != null && tipoSolicitacaoCompleto.eventoResposta == true ?
                                                <div>

                                                    <Row>

                                                        <Form.Group as={Col} md={10} controlId="formIdInteressado">
                                                            <Form.Label>Interessado</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                type="text"
                                                                value={interessado}
                                                                disabled
                                                                required
                                                                maxLength="50"
                                                            //isInvalid={validated && numeroProcesso != '' && numeroProcesso != null && numeroProcesso.trim() === "" ? true : false}
                                                            //isValid={validated && numeroProcesso != '' && numeroProcesso != null && numeroProcesso.trim() !== "" ? true : false}
                                                            />
                                                            <Form.Control.Feedback type="invalid">O campo Interessado é obrigatório.</Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md={2} controlId="formIdInteressado">
                                                            <Form.Label style={{ visibility: 'hidden' }}>.</Form.Label>
                                                            <Button variant="success" style={{ width: '100%' }} onClick={SelecionarInteressado}>Selecionar</Button>
                                                        </Form.Group>

                                                    </Row>
                                                    <br />
                                                    <Row className="mb-3">

                                                        <Form.Group as={Col} md={6} controlId="formEndereco">
                                                            <Form.Label>Endereço Interessado</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                type="text"
                                                                value={endereco}
                                                                disabled
                                                                onChange={(e) => setEndereco(e.target.value)}
                                                                required
                                                                maxLength="400"
                                                                isInvalid={validated && endereco != null && endereco.trim() === "" ? true : false}
                                                                isValid={validated && endereco != null && endereco.trim() !== "" ? true : false}
                                                            />
                                                            <Form.Control.Feedback type="invalid">O campo Endereço é obrigatório.</Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md={1} controlId="formDDD">
                                                            <Form.Label>DDD Interessado</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                as={InputMask}
                                                                disabled
                                                                mask='99'
                                                                value={ddd}
                                                                onChange={(e) => setDdd(e.target.value)}
                                                            //isInvalid={validated && (ddd == undefined || ddd == null || ddd == '') ? false :
                                                            //  validated && !/^\d{2}$/.test(String(ddd)) ? true : false}
                                                            // isValid={validated && (ddd == undefined || ddd == null || ddd == '') ? true :
                                                            //     validated && /^\d{2}$/.test(String(ddd)) ? true : false}
                                                            />
                                                            {/*<Form.Control.Feedback type="invalid">O campo DDD esta com formato invalido.</Form.Control.Feedback>*/}
                                                        </Form.Group>

                                                        <Form.Group as={Col} md={2} controlId="formTelefone">
                                                            <Form.Label>Telefone Celular Interessado</Form.Label><br />
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                as={InputMask}
                                                                disabled
                                                                mask='99999 9999'
                                                                value={telefone}
                                                                onChange={(e) => setTelefone(e.target.value)}>
                                                            </Form.Control>
                                                            {/*<Form.Control.Feedback type="invalid">O campo Telefone esta com formato invalido.</Form.Control.Feedback>*/}
                                                        </Form.Group>

                                                        <Form.Group as={Col} md={1} controlId="formDDDFixo">
                                                            <Form.Label>DDD Fixo Interessado</Form.Label>
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                as={InputMask}
                                                                disabled
                                                                mask='99'
                                                                value={dddFixo}
                                                                onChange={(e) => setDddFixo(e.target.value)}
                                                            //isInvalid={validated && (dddFixo == undefined || dddFixo == null || dddFixo == '') ? false :
                                                            //   validated && !/^\d{2}$/.test(String(dddFixo)) ? true : false}
                                                            // isValid={validated && (dddFixo == undefined || dddFixo == null || dddFixo == '') ? true :
                                                            //   validated && /^\d{2}$/.test(String(dddFixo)) ? true : false}
                                                            />
                                                            {/*<Form.Control.Feedback type="invalid">O campo DDD Fixo esta com formato invalido.</Form.Control.Feedback>*/}
                                                        </Form.Group>

                                                        <Form.Group as={Col} md={2} controlId="formTelefoneFixo">
                                                            <Form.Label>Telefone Fixo Interessado</Form.Label><br />
                                                            <Form.Control
                                                                style={{ minHeight: '36px' }}
                                                                as={InputMask}
                                                                disabled
                                                                mask='9999 9999'
                                                                value={telefoneFixo}
                                                                onChange={(e) => setTelefoneFixo(e.target.value)}
                                                            // isInvalid={validated && (telefoneFixo == undefined || telefoneFixo == null || telefoneFixo == '') ? false :
                                                            //     validated && !/^\d{4} \d{4}$/.test(String(telefoneFixo)) ? true : false}
                                                            // isValid={validated && (telefoneFixo == undefined || telefoneFixo == null || telefoneFixo == '') ? true :
                                                            //    validated && (/^\d{4} \d{4}$/.test(String(telefoneFixo))) ? true : false} 
                                                            >
                                                            </Form.Control>
                                                            {/* <Form.Control.Feedback type="invalid">O campo Telefone Fixo esta com formato invalido.</Form.Control.Feedback> */}
                                                        </Form.Group>
                                                    </Row>
                                                </div>
                                                :
                                                ''
                                        :
                                        ''
                                }

                                <div>
                                    {
                                        idTipoSolicitacao != "" ?

                                            tipoSolicitacaoCompleto != null && tipoSolicitacaoCompleto.listaFormularioCaracteristica != null ?
                                                tipoSolicitacaoCompleto.listaFormularioCaracteristica.map(function (m, y) {

                                                    if (y % 2 == 0 && tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1] != null) {
                                                        return (
                                                            <Row className="mb-3" id={y.toString()}>
                                                                {
                                                                    m.caracteristica.tipoCaracteristica.descricao == 'select' ?
                                                                        m.caracteristica.id == 17 ?
                                                                            <Form.Group as={Col} md={6}>
                                                                                <Row>
                                                                                    <Form.Group as={Col} md={4}>
                                                                                        <Form.Label>{m.caracteristica.descricaoLabel}</Form.Label>
                                                                                        <Form.Control
                                                                                            disabled
                                                                                            style={{ minHeight: '36px' }}
                                                                                            type={m.caracteristica.tipoCaracteristica.descricao}
                                                                                            //as= {m.caracteristica.tipoCaracteristica.inputGrande ? "textarea" : "text" }
                                                                                            //disabled={id != undefined ? true : false}
                                                                                            value={
                                                                                                listaResposta != null && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null ?
                                                                                                    m.caracteristica.caracteristicaResposta.filter(x => x.id == listaResposta
                                                                                                        .find(x => x.descricao == m.caracteristica.descricao).idCaracteristicaResposta).length > 0 ?
                                                                                                        m.caracteristica.caracteristicaResposta.filter(x => x.id == listaResposta
                                                                                                            .find(x => x.descricao == m.caracteristica.descricao).idCaracteristicaResposta)[0].descricao : '' : ''
                                                                                            }
                                                                                            onChange={e => MudarValor(m.caracteristica.descricao, e.target.value, m.caracteristica.tipoCaracteristica.descricao,
                                                                                                m.caracteristica.caracteristicaResposta[0].id)}
                                                                                            maxLength="150"
                                                                                            required={m.caracteristica.nulavel ? false : true}
                                                                                            isInvalid={
                                                                                                validated && !m.caracteristica.nulavel && listaResposta != null && listaResposta.length > 0
                                                                                                    && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                                    listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? true : false}
                                                                                            isValid={validated && !m.caracteristica.nulavel && listaResposta != null && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                                listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor !== null ? true : false}
                                                                                        />
                                                                                        <Form.Control.Feedback type="invalid">O campo {m.caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                    <Form.Group as={Col} md={2} controlId="formIdInteressado">
                                                                                        <Form.Label style={{ visibility: 'hidden' }}>.</Form.Label>
                                                                                        <Button variant="success" style={{ width: '100%' }} onClick={SelecionarInteressado}>Selecionar Destinatário</Button>
                                                                                    </Form.Group>
                                                                                </Row>
                                                                            </Form.Group>
                                                                            :
                                                                            <Form.Group as={Col} md={6}>
                                                                                <Form.Label>{m.caracteristica.descricaoLabel}</Form.Label>
                                                                                <Select
                                                                                    styles={{
                                                                                        control: (styles) => ({
                                                                                            ...styles, borderColor: validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? 'red' : '#C0C0C0'
                                                                                        }),
                                                                                        placeholder: (styles) => ({
                                                                                            ...styles, color: validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? 'red' : '#C0C0C0'
                                                                                        })
                                                                                    }}
                                                                                    placeholder="Selecione..."
                                                                                    isClearable={true}
                                                                                    isSearchable={true}
                                                                                    defaultValue={listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                        m.caracteristica.caracteristicaResposta != undefined ?

                                                                                        {
                                                                                            value: listaResposta.find(x => x.descricao == m.caracteristica.descricao).idCaracteristicaResposta,
                                                                                            label: m.caracteristica.caracteristicaResposta
                                                                                                .filter(x => x.id == listaResposta.find(x => x.descricao == m.caracteristica.descricao).idCaracteristicaResposta).length > 0 ?
                                                                                                m.caracteristica.caracteristicaResposta
                                                                                                    .filter(x => x.id == listaResposta.find(x => x.descricao == m.caracteristica.descricao).idCaracteristicaResposta)[0].descricao : ''
                                                                                        } : ''
                                                                                    }
                                                                                    options={
                                                                                        m.caracteristica.caracteristicaResposta != undefined && m.caracteristica.caracteristicaResposta.length > 0 ?
                                                                                            [...m.caracteristica.caracteristicaResposta].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                                                value: m.id, label: m.descricao
                                                                                            }))
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                    onChange={e => MudarValor(m.caracteristica.descricao, e != null ? e.value.toString() : '', m.caracteristica.tipoCaracteristica.descricao,
                                                                                        m.caracteristica.caracteristicaResposta[0].id)}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">O campo {m.caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                        :
                                                                        m.caracteristica.tipoCaracteristica.inputLargo ?
                                                                            <Form.Group as={Col} md={12}>
                                                                                <Form.Label>
                                                                                    {m.caracteristica.descricaoLabel} 
                                                                                    {m.caracteristica.descricaoLabel === "Memorando" ?
                                                                                        <span style={{color:'red', fontSize: '11px'}}> 
                                                                                            <br/>
                                                                                            *Favor adicionar ao texto, se necessário, o destinatário desejado. Ex.: "Prezado Coordenador, ".
                                                                                        </span>
                                                                                        :
                                                                                        ''
                                                                                    }
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type={m.caracteristica.tipoCaracteristica.descricao}
                                                                                    as={"textarea"}
                                                                                    style={
                                                                                        {
                                                                                            'height': '180px'
                                                                                        }
                                                                                    }
                                                                                    //disabled={id != undefined ? true : false}
                                                                                    value={
                                                                                        listaResposta != null && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null ?
                                                                                            listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor : ''
                                                                                    }
                                                                                    onChange={e => MudarValor(m.caracteristica.descricao, e.target.value, m.caracteristica.tipoCaracteristica.descricao,
                                                                                        m.caracteristica.caracteristicaResposta[0].id)}
                                                                                    maxLength="10000"
                                                                                    required={m.caracteristica.nulavel ? false : true}
                                                                                    isInvalid={
                                                                                        validated && !m.caracteristica.nulavel && listaResposta != null && listaResposta.length > 0
                                                                                            && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                            listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? true : false}
                                                                                    isValid={validated && !m.caracteristica.nulavel && listaResposta != null && listaResposta.length > 0
                                                                                        && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                        listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor !== null ? true : false}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">O campo {m.caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            :
                                                                            m.caracteristica.tipoCaracteristica.inputGrande ?
                                                                                <Form.Group as={Col} md={12}>
                                                                                    <Form.Label>{m.caracteristica.descricaoLabel}</Form.Label>
                                                                                    <Form.Control
                                                                                        style={{ minHeight: '36px' }}
                                                                                        type={m.caracteristica.tipoCaracteristica.descricao}
                                                                                        //as= {m.caracteristica.tipoCaracteristica.inputGrande ? "textarea" : "text" }
                                                                                        //disabled={id != undefined ? true : false}
                                                                                        value={
                                                                                            listaResposta != null && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null ?
                                                                                                listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor : ''
                                                                                        }
                                                                                        onChange={e => MudarValor(m.caracteristica.descricao, e.target.value, m.caracteristica.tipoCaracteristica.descricao,
                                                                                            m.caracteristica.caracteristicaResposta[0].id)}
                                                                                        maxLength="150"
                                                                                        required={m.caracteristica.nulavel ? false : true}
                                                                                        isInvalid={
                                                                                            validated && !m.caracteristica.nulavel && listaResposta != null && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                                listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? true : false}
                                                                                        isValid={validated && !m.caracteristica.nulavel && listaResposta != null && listaResposta.length > 0
                                                                                            && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                            listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor !== null ? true : false}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">O campo {m.caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                                </Form.Group>
                                                                                :
                                                                                <Form.Group as={Col} md={6}>
                                                                                    <Form.Label>{m.caracteristica.descricaoLabel}</Form.Label>
                                                                                    <Form.Control
                                                                                        style={{ minHeight: '36px' }}
                                                                                        type={m.caracteristica.tipoCaracteristica.descricao}
                                                                                        //as= {m.caracteristica.tipoCaracteristica.inputGrande ? "textarea" : "text" }
                                                                                        //disabled={id != undefined ? true : false}
                                                                                        value={
                                                                                            listaResposta != null && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null ?
                                                                                                listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor : ''
                                                                                        }
                                                                                        onChange={e => MudarValor(m.caracteristica.descricao, e.target.value, m.caracteristica.tipoCaracteristica.descricao,
                                                                                            m.caracteristica.caracteristicaResposta[0].id)}
                                                                                        maxLength="150"
                                                                                        required={m.caracteristica.nulavel ? false : true}
                                                                                        isInvalid={
                                                                                            validated && !m.caracteristica.nulavel && listaResposta != null && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                                listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? true : false}
                                                                                        isValid={validated && !m.caracteristica.nulavel && listaResposta != null && listaResposta.length > 0
                                                                                            && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                            listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor !== null ? true : false}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">O campo {m.caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                                </Form.Group>
                                                                }

                                                                {
                                                                    tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.descricao == 'select' ?
                                                                        tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.id == 17 ?
                                                                            <>
                                                                                <Form.Group as={Col} md={4}>
                                                                                    <Form.Label>{tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricaoLabel}</Form.Label>
                                                                                    <Form.Control
                                                                                        disabled
                                                                                        style={{ minHeight: '36px' }}
                                                                                        type={tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.descricao}
                                                                                        //disabled={id != undefined ? true : false}
                                                                                        //as= {tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.inputGrande ? "textarea" : "" }
                                                                                        value={
                                                                                            listaResposta != null && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null ?
                                                                                                tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta.filter(
                                                                                                    x => x.id == listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).idCaracteristicaResposta
                                                                                                ).length > 0 ?
                                                                                                    tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta.filter(
                                                                                                        x => x.id == listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).idCaracteristicaResposta
                                                                                                    )[0].descricao
                                                                                                    : ''
                                                                                                : ''
                                                                                        }
                                                                                        onChange={e => MudarValor(tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao, e.target.value, tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.descricao,
                                                                                            tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta[0].id)}
                                                                                        maxLength="150"
                                                                                        required={tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel ? false : true}
                                                                                        isInvalid={
                                                                                            validated && !tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor === "" ? true : false}
                                                                                        isValid={validated && !tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel && listaResposta.length > 0
                                                                                            && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null
                                                                                            && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor !== null ? true : false}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">O campo {tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md={2} controlId="formIdDestinatarioDinamico">
                                                                                    <Form.Label style={{ visibility: 'hidden' }}>.</Form.Label>
                                                                                    <Button variant="success" style={{ width: '100%' }} onClick={SelecionarDestinatarioDinamico}>Selecionar Destinatário</Button>
                                                                                </Form.Group>
                                                                            </>
                                                                            :
                                                                            <Form.Group as={Col} md={6}>
                                                                                <Form.Label>{tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricaoLabel}</Form.Label>

                                                                                <Select
                                                                                    styles={{
                                                                                        control: (styles) => ({
                                                                                            ...styles, borderColor: validated && !tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor === "" ? 'red' : '#C0C0C0'
                                                                                        }),
                                                                                        placeholder: (styles) => ({
                                                                                            ...styles, color: validated && !tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor === "" ? 'red' : '#C0C0C0'
                                                                                        })
                                                                                    }}
                                                                                    placeholder="Selecione..."
                                                                                    isClearable={true}
                                                                                    isSearchable={true}
                                                                                    defaultValue={listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                        m.caracteristica.caracteristicaResposta != undefined
                                                                                        && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null ?
                                                                                        {
                                                                                            value: listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).idCaracteristicaResposta,
                                                                                            label: tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta != null && tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta
                                                                                                .filter(x => x.id == listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).idCaracteristicaResposta).length > 0 ?
                                                                                                tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta
                                                                                                    .filter(x => x.id == listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).idCaracteristicaResposta)[0].descricao : ''
                                                                                        }
                                                                                        : ''
                                                                                    }
                                                                                    options={
                                                                                        tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta != undefined && m.caracteristica.caracteristicaResposta.length > 0 ?
                                                                                            [...tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1]
                                                                                                .caracteristica.caracteristicaResposta].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                                                    value: m.id, label: m.descricao
                                                                                                }))
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                    onChange={
                                                                                        e => MudarValor(tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao, e != null ? e.value.toString() : '', tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.descricao,
                                                                                            tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta[0].id)}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">O campo {tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                        :
                                                                        tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.inputLargo ?
                                                                            <Form.Group as={Col} md={12}>
                                                                                <Form.Label>{tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricaoLabel}</Form.Label>
                                                                                <Form.Control
                                                                                    type={tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.descricao}
                                                                                    //disabled={id != undefined ? true : false}
                                                                                    as={"textarea"}
                                                                                    style={
                                                                                        {
                                                                                            'height': '180px'
                                                                                        }
                                                                                    }
                                                                                    value={
                                                                                        listaResposta != null && listaResposta.length > 0
                                                                                            && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null ?
                                                                                            listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor : ''
                                                                                    }
                                                                                    onChange={e => MudarValor(tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao, e.target.value, tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.descricao,
                                                                                        tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta[0].id)}
                                                                                    maxLength="10000"
                                                                                    required={tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel ? false : true}
                                                                                    isInvalid={
                                                                                        validated && !tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel && listaResposta.length > 0
                                                                                            && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null
                                                                                            && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor === "" ? true : false}
                                                                                    isValid={validated && !tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel && listaResposta.length > 0
                                                                                        && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null
                                                                                        && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor !== null ? true : false}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">O campo {tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            :
                                                                            tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.inputGrande ?
                                                                                <Form.Group as={Col} md={12}>
                                                                                    <Form.Label>{tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricaoLabel}</Form.Label>
                                                                                    <Form.Control
                                                                                        style={{ minHeight: '36px' }}
                                                                                        type={tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.descricao}
                                                                                        //disabled={id != undefined ? true : false}
                                                                                        //as= {tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.inputGrande ? "textarea" : "" }
                                                                                        value={
                                                                                            listaResposta != null && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null ?
                                                                                                listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor : ''
                                                                                        }
                                                                                        onChange={e => MudarValor(tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao, e.target.value, tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.descricao,
                                                                                            tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta[0].id)}
                                                                                        maxLength="150"
                                                                                        required={tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel ? false : true}
                                                                                        isInvalid={
                                                                                            validated && !tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor === "" ? true : false}
                                                                                        isValid={validated && !tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel && listaResposta.length > 0
                                                                                            && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null
                                                                                            && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor !== null ? true : false}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">O campo {tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                                </Form.Group>
                                                                                :
                                                                                <Form.Group as={Col} md={6}>
                                                                                    <Form.Label>{tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricaoLabel}</Form.Label>
                                                                                    <Form.Control
                                                                                        style={{ minHeight: '36px' }}
                                                                                        type={tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.descricao}
                                                                                        //disabled={id != undefined ? true : false}
                                                                                        //as= {tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.inputGrande ? "textarea" : "" }
                                                                                        value={
                                                                                            listaResposta != null && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null ?
                                                                                                listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor : ''
                                                                                        }
                                                                                        onChange={e => MudarValor(tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao, e.target.value, tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.tipoCaracteristica.descricao,
                                                                                            tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.caracteristicaResposta[0].id)}
                                                                                        maxLength="150"
                                                                                        required={tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel ? false : true}
                                                                                        isInvalid={
                                                                                            validated && !tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel && listaResposta.length > 0
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor === "" ? true : false}
                                                                                        isValid={validated && !tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.nulavel && listaResposta.length > 0
                                                                                            && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao) != null
                                                                                            && listaResposta.find(x => x.descricao == tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricao).valor !== null ? true : false}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">O campo {tipoSolicitacaoCompleto.listaFormularioCaracteristica[y + 1].caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                                </Form.Group>
                                                                }

                                                            </Row>
                                                        )
                                                    } else if (y % 2 == 0) {
                                                        return (
                                                            <Row className="mb-3" id={y.toString()}>

                                                                {
                                                                    m.caracteristica.tipoCaracteristica.descricao == 'select' ?
                                                                        m.caracteristica.id === 17 ?
                                                                            <Form.Group as={Col} md={6}>
                                                                                <Row>
                                                                                    <Form.Group as={Col} md={4}>
                                                                                        <Form.Label>{m.caracteristica.descricaoLabel}</Form.Label>
                                                                                        <Form.Control
                                                                                            disabled
                                                                                            style={{ minHeight: '36px' }}
                                                                                            type={m.caracteristica.tipoCaracteristica.descricao}
                                                                                            //as= {m.caracteristica.tipoCaracteristica.inputGrande ? "textarea" : "" }
                                                                                            //disabled={id != undefined ? true : false}
                                                                                            value={
                                                                                                listaResposta != null && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null ?
                                                                                                    m.caracteristica.caracteristicaResposta.filter(x => x.id == listaResposta
                                                                                                        .find(x => x.descricao == m.caracteristica.descricao).idCaracteristicaResposta).length > 0 ?
                                                                                                        m.caracteristica.caracteristicaResposta.filter(x => x.id == listaResposta
                                                                                                            .find(x => x.descricao == m.caracteristica.descricao).idCaracteristicaResposta)[0].descricao : '' : ''
                                                                                            }
                                                                                            onChange={e => MudarValor(m.caracteristica.descricao, e.target.value, m.caracteristica.tipoCaracteristica.descricao,
                                                                                                m.caracteristica.caracteristicaResposta[0].id)}
                                                                                            maxLength="150"
                                                                                            required={m.caracteristica.nulavel ? false : true}
                                                                                            isInvalid={
                                                                                                validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                                    && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? true : false}
                                                                                            isValid={validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor !== null ? true : false}
                                                                                        />
                                                                                        <Form.Control.Feedback type="invalid">O campo {m.caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                    <Form.Group as={Col} md={2} controlId="formIdInteressado">
                                                                                        <Form.Label style={{ visibility: 'hidden' }}>.</Form.Label>
                                                                                        <Button variant="success" style={{ width: '100%' }} onClick={SelecionarInteressado}>Selecionar Destinatário</Button>
                                                                                    </Form.Group>
                                                                                </Row>
                                                                            </Form.Group>
                                                                            :
                                                                            <Form.Group as={Col} md={6}>
                                                                                <Form.Label>{m.caracteristica.descricaoLabel}</Form.Label>
                                                                                <Select
                                                                                    styles={{
                                                                                        control: (styles) => ({
                                                                                            ...styles, borderColor: validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? 'red' : '#C0C0C0'
                                                                                        }),
                                                                                        placeholder: (styles) => ({
                                                                                            ...styles, color: validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? 'red' : '#C0C0C0'
                                                                                        })
                                                                                    }}
                                                                                    placeholder="Selecione..."
                                                                                    isClearable={true}
                                                                                    isSearchable={true}
                                                                                    defaultValue={listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null &&
                                                                                        m.caracteristica.caracteristicaResposta != undefined ?

                                                                                        {
                                                                                            value: listaResposta.find(x => x.descricao == m.caracteristica.descricao).idCaracteristicaResposta,
                                                                                            label: m.caracteristica.caracteristicaResposta
                                                                                                .filter(x => x.id == listaResposta.find(x => x.descricao == m.caracteristica.descricao).idCaracteristicaResposta).length > 0 ?
                                                                                                m.caracteristica.caracteristicaResposta
                                                                                                    .filter(x => x.id == listaResposta.find(x => x.descricao == m.caracteristica.descricao).idCaracteristicaResposta)[0].descricao : ''
                                                                                        } : ''
                                                                                    }
                                                                                    options={
                                                                                        m.caracteristica.caracteristicaResposta != undefined && m.caracteristica.caracteristicaResposta.length > 0 ?
                                                                                            [...m.caracteristica.caracteristicaResposta].sort((a, b) => a.descricao.localeCompare(b.descricao)).map(m => ({
                                                                                                value: m.id, label: m.descricao
                                                                                            }))
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                    onChange={e => MudarValor(m.caracteristica.descricao, e != null ? e.value.toString() : '', m.caracteristica.tipoCaracteristica.descricao,
                                                                                        m.caracteristica.caracteristicaResposta[0].id)}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">O campo {m.caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                        :
                                                                        m.caracteristica.tipoCaracteristica.inputLargo ?
                                                                            <Form.Group as={Col} md={12}>
                                                                                <Form.Label>{m.caracteristica.descricaoLabel}</Form.Label>
                                                                                <Form.Control
                                                                                    type={m.caracteristica.tipoCaracteristica.descricao}
                                                                                    as={"textarea"}
                                                                                    style={
                                                                                        {
                                                                                            'height': '180px'
                                                                                        }
                                                                                    }
                                                                                    //disabled={id != undefined ? true : false}
                                                                                    value={
                                                                                        listaResposta != null && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null ?
                                                                                            listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor : ''
                                                                                    }
                                                                                    onChange={e => MudarValor(m.caracteristica.descricao, e.target.value, m.caracteristica.tipoCaracteristica.descricao,
                                                                                        m.caracteristica.caracteristicaResposta[0].id)}
                                                                                    maxLength="10000"
                                                                                    required={m.caracteristica.nulavel ? false : true}
                                                                                    isInvalid={
                                                                                        validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                            && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? true : false}
                                                                                    isValid={validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                        && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor !== null ? true : false}
                                                                                />
                                                                                <Form.Control.Feedback type="invalid">O campo {m.caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            :
                                                                            m.caracteristica.tipoCaracteristica.inputGrande ?
                                                                                <Form.Group as={Col} md={12}>
                                                                                    <Form.Label>{m.caracteristica.descricaoLabel}</Form.Label>
                                                                                    <Form.Control
                                                                                        style={{ minHeight: '36px' }}
                                                                                        type={m.caracteristica.tipoCaracteristica.descricao}
                                                                                        //as= {m.caracteristica.tipoCaracteristica.inputGrande ? "textarea" : "" }
                                                                                        //disabled={id != undefined ? true : false}
                                                                                        value={
                                                                                            listaResposta != null && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null ?
                                                                                                listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor : ''
                                                                                        }
                                                                                        onChange={e => MudarValor(m.caracteristica.descricao, e.target.value, m.caracteristica.tipoCaracteristica.descricao,
                                                                                            m.caracteristica.caracteristicaResposta[0].id)}
                                                                                        maxLength="150"
                                                                                        required={m.caracteristica.nulavel ? false : true}
                                                                                        isInvalid={
                                                                                            validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? true : false}
                                                                                        isValid={validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                            && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor !== null ? true : false}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">O campo {m.caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                                </Form.Group>
                                                                                :
                                                                                <Form.Group as={Col} md={6}>
                                                                                    <Form.Label>{m.caracteristica.descricaoLabel}</Form.Label>
                                                                                    <Form.Control
                                                                                        style={{ minHeight: '36px' }}
                                                                                        type={m.caracteristica.tipoCaracteristica.descricao}
                                                                                        //as= {m.caracteristica.tipoCaracteristica.inputGrande ? "textarea" : "" }
                                                                                        //disabled={id != undefined ? true : false}
                                                                                        value={
                                                                                            listaResposta != null && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null ?
                                                                                                listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor : ''
                                                                                        }
                                                                                        onChange={e => MudarValor(m.caracteristica.descricao, e.target.value, m.caracteristica.tipoCaracteristica.descricao,
                                                                                            m.caracteristica.caracteristicaResposta[0].id)}
                                                                                        maxLength="150"
                                                                                        required={m.caracteristica.nulavel ? false : true}
                                                                                        isInvalid={
                                                                                            validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                                && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor === "" ? true : false}
                                                                                        isValid={validated && !m.caracteristica.nulavel && listaResposta.length > 0 && listaResposta.find(x => x.descricao == m.caracteristica.descricao) != null
                                                                                            && listaResposta.find(x => x.descricao == m.caracteristica.descricao).valor !== null ? true : false}
                                                                                    />
                                                                                    <Form.Control.Feedback type="invalid">O campo {m.caracteristica.descricaoLabel} é obrigatório.</Form.Control.Feedback>
                                                                                </Form.Group>
                                                                }
                                                            </Row>
                                                        )
                                                    }
                                                })
                                                :
                                                null
                                            :
                                            null
                                    }
                                </div>

                                <Row>
                                    {
                                        id == undefined
                                            ?
                                            <Col md={5}>
                                                {
                                                    id == undefined && inserir == true
                                                        ?
                                                        <Col md={4}>
                                                            <Button type="submit" variant="success" style={{ width: '100%' }}>Cadastrar</Button>
                                                        </Col>

                                                        : alterar == true
                                                            ?
                                                            <Col md={4}>
                                                                <Button type="submit" variant="success" style={{ width: '100%' }}>Editar</Button>
                                                            </Col>
                                                            :
                                                            ''
                                                }
                                            </Col>
                                            :
                                            listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                                                listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true).length > 0 &&
                                                listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.cancelaSolicitacao == true)[0].id).length > 0
                                                ?
                                                <Col md={12}>
                                                    <Alert variant={`danger`} style={{ width: '100%' }}>
                                                        A Solicitação está Cancelada!
                                                    </Alert>
                                                </Col>
                                                :
                                                listaSolicitacaoFornecimentoVersaoStatus != undefined && listaStatusSolicitacaoFornecimentoCompleto != undefined &&
                                                    listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true).length > 0 &&
                                                    listaSolicitacaoFornecimentoVersaoStatus.filter(x => x.idStatusSolicitacaoFornecimento ==
                                                        listaStatusSolicitacaoFornecimentoCompleto.filter(x => x.finalizaSolicitacao == true)[0].id).length > 0 &&
                                                        usuarioCompleto && usuarioCompleto.grupoAcesso &&
                                                        usuarioCompleto.grupoAcesso.nomeGrupo !== "Super Administrador"
                                                    ?
                                                    <Col md={12}>
                                                        <Alert variant={`success`} style={{ width: '100%' }}>
                                                            A Solicitação está Finalizada!
                                                        </Alert>
                                                    </Col>
                                                    :
                                                    listaSolicitacaoFornecimentoVersaoStatus != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                                    [listaSolicitacaoFornecimentoVersaoStatus.length - 1] != undefined && listaSolicitacaoFornecimentoVersaoStatus
                                                    [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento != null && listaSolicitacaoFornecimentoVersaoStatus
                                                    [listaSolicitacaoFornecimentoVersaoStatus.length - 1].statusSolicitacaoFornecimento.somenteLeitura == true &&
                                                    usuarioCompleto && usuarioCompleto.grupoAcesso &&
                                                    usuarioCompleto.grupoAcesso.nomeGrupo !== "Super Administrador" ?
                                                        <Col md={12}>
                                                            <Alert variant={`success`} style={{ width: '100%' }}>
                                                                Status atual desta solicitação só permite leitura!
                                                            </Alert>
                                                        </Col>
                                                        :
                                                        <Row className="mb-3">
                                                            <Col md={5}>
                                                                {
                                                                    id == undefined && inserir == true
                                                                        ?
                                                                        <Col md={4}>
                                                                            <Button variant="success" type="submit" style={{ width: '100%' }}>Cadastrar</Button>
                                                                        </Col>

                                                                        : alterar == true
                                                                            ?
                                                                            <Col md={4}>
                                                                                <Button variant="success" type="submit" style={{ width: '100%' }}>Editar</Button>
                                                                            </Col>
                                                                            :
                                                                            ''
                                                                }
                                                            </Col>
                                                        </Row>
                                    }
                                    <Row>
                                        {
                                            id != undefined ?

                                                (tipoSolicitacaoCompleto != null && tipoSolicitacaoCompleto.servico && tipoSolicitacaoCompleto.idFormulario != 2)
                                                || (tipoSolicitacaoCompleto != null && (tipoSolicitacaoCompleto.id == 148 || tipoSolicitacaoCompleto.id == 149 || tipoSolicitacaoCompleto.id == 150)) ?
                                                    <Col md={3}>
                                                        <PDFDownloadLink onClick={exportaFotos} document={
                                                            <PdfSolicitacaoFornecimento
                                                                data={listaDadosExportarPDFSolicitacaoFornecimento != null ? listaDadosExportarPDFSolicitacaoFornecimento : null}
                                                            />} fileName="solicitacaoFornecimento.pdf">
                                                            <Button
                                                                variant="success"
                                                                style={{ width: '100%' }}>
                                                                Exportar Solicitação Orçamento
                                                            </Button>
                                                            {({ blob, url, loading, error }) => (loading ? 'Carregando autorização fornecimento...' : 'Exportar como PDF')}
                                                        </PDFDownloadLink>
                                                    </Col>
                                                    :
                                                    tipoSolicitacaoCompleto != null && tipoSolicitacaoCompleto.semOficio == false && tipoSolicitacaoCompleto.eventoResposta == false ?
                                                        <Col md={3}>
                                                            <PDFDownloadLink onClick={exportaFotosEvento} document={
                                                                <PdfOrcamentoEvento
                                                                    data={listaDadosExportarPDFSolicitacaoFornecimentoEvento != null ? listaDadosExportarPDFSolicitacaoFornecimentoEvento : null}
                                                                />} fileName="solicitacaoFornecimentoEvento.pdf">
                                                                <Button
                                                                    variant="success"
                                                                    style={{ width: '100%' }}>
                                                                    Exportar Solicitação Orçamento com Evento
                                                                </Button>
                                                                {({ blob, url, loading, error }) => (loading ? 'Carregando autorização fornecimento Evento...' : 'Exportar como PDF')}
                                                            </PDFDownloadLink>
                                                        </Col>
                                                        :
                                                        ''
                                                :
                                                ''
                                        }
                                    </Row>
                                    <Row>
                                        {
                                            id == undefined || (solicitacaoFornecimento != null && solicitacaoFornecimento.numeroOficio != '') ?
                                                ''
                                                :
                                                <Col md='auto'>
                                                    <Button
                                                        variant={"danger"}
                                                        style={{ width: '100%', marginTop: '10%' }}
                                                        onClick={() => GerarOficio()}>
                                                        Gerar Ofício
                                                    </Button>
                                                </Col>
                                        }
                                    </Row>
                                </Row>
                            </Col>
                        </div>
                    </Form >
                    :
                    <Loading />
            }

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Atenção</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={12}>
                                Foi alterado o número de orçamento. Você deseja criar uma nova versão de solicitação?
                            </Col>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={12} controlId="formDescricao">
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={observacaoVersao}
                                        onChange={e => setObservacaoVersao(e.target.value)}
                                        required
                                        as="textarea"
                                        rows="5"
                                        maxLength="500"
                                        isInvalid={validatedObservacaoNovaVersao && observacaoVersao === "" ? true : false}
                                        isValid={validatedObservacaoNovaVersao && observacaoVersao !== "" ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">O campo observação é obrigatório.</Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {
                        inserir == true ?
                            <Button variant="success" onClick={handleSubmitNovaVersao}>
                                Sim
                            </Button>
                            :
                            ''

                    }
                    {
                        alterar == true ?
                            <Button variant="danger" onClick={AlterarSolicitacao}>
                                Não
                            </Button>
                            :
                            ''
                    }

                </Modal.Footer>
            </Modal>

            <ModalInteressado
                show={showInteressado}
                onHide={handleCloseInteressado}
                interessadoSelecinado={setInteressadoSelecinado}
            />

            <ModalOrgao
                show={showOrgao}
                onHide={handleCloseOrgao}
                orgaoSelecinado={setOrgaoSelecinado}
                orgaoSet={setOrgao}
            />

            <ModalDestinatario
                show={showDestinatario}
                onHide={handleCloseDestinatario}
                destinatarioSelecinado={setDestinatarioSelecionado}
                idSolicitacaoFornecimento={id}
                idTipoSolicitacao={idTipoSolicitacao}
            />
        </>
    )
}